import React from 'react';
import { connect } from 'react-redux';
import Footer from '../../components/common/Footer';
import ProvinceHeader from './widget/ProvinceHeader';
import {
  getLocaleDetail,
  getLocaleName,
  getLocaleTitle,
  withNavigateHook,
} from '../../helper/Tools';
import * as WeatherTool from '../../helper/Weather';
import IntlMessages from '../../components/common/intlMessages';
import {
  getHomeData,
  getNewestBlogs,
  getPlaceList,
  getRecommendTripLimit,
  getTagModelList,
  getZonesList,
} from '../../helper/FirestoreApi';
import { FaArrowRight } from 'react-icons/fa';
import { interTransit, intraTransit } from '../../helper/defaultValues';
import GlideComponent from '../../components/carousel/GlideComponent';
import ScrollContainer from 'react-indiana-drag-scroll';

// let result = {
//     "latitude": 13.749955,
//     "longitude": 100.519427,
//     "timezone": "Asia/Bangkok",
//     "currently": {
//         "time": 1669087912,
//         "summary": "Humid and Mostly Cloudy",
//         "icon": "partly-cloudy-day",
//         "precipIntensity": 0.035,
//         "precipProbability": 0.05,
//         "precipType": "rain",
//         "temperature": 31.17,
//         "apparentTemperature": 37.08,
//         "dewPoint": 24.26,
//         "humidity": 0.67,
//         "pressure": 1009.4,
//         "windSpeed": 2.81,
//         "windGust": 3.65,
//         "windBearing": 14,
//         "cloudCover": 0.8,
//         "uvIndex": 5,
//         "visibility": 16.093,
//         "ozone": 254.5
//     },
//     "flags": {
//         "sources": [
//             "cmc",
//             "gfs",
//             "icon",
//             "isd",
//             "madis"
//         ],
//         "nearest-station": 5.477,
//         "units": "si"
//     },
//     "offset": 7
// };
const highlighTSettings = {
  type: 'carousel',
  gap: 10,
  perView: 2,
  startAt: 0,
  hideNav: true,
  hideFooter: true,
  hideDots: true,
  breakpoints: {
    500: { perView: 2, gap: 10 },
    700: { perView: 3, gap: 10 },
    9999: { perView: 4, gap: 10 },
  },
};

class ProvicneContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weather: null,
      selectedArea: null,
      top_menu: null,
      highlight: null,
      zones: null,
      foods: null,
      activities: null,
      blogs: null,
      journey: null,
    };
  }
  async componentDidMount() {
    let { area, locale } = this.props;
    let { appid } = this.props.params;
    let selected = area.find((g) => g.id === appid);

    if (selected !== undefined) {
      // let weather = result.currently;
      // this.setState({
      //     weather: weather,
      // });
      let current = this;

      fetch(
        `/api/v1/getAreaWeather?lag=${selected.latitude}&lon=${selected.longitude}`,
        {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'no-cors', // no-cors, *cors, same-origin
        }
      )
        .then(function (response) {
          if (!response.ok) {
            return Promise.reject('some reason');
          }
          return response.json();
        })
        .then((result) => {
          let weather = result.currently;
          current.setState({
            weather: weather,
          });
        });

      let homeData = await getHomeData(selected.value);
      let highlight = await getPlaceList(
        homeData.look_around.map((e) => e.place_id),
        locale
      );
      highlight.forEach((element) => {
        let look = homeData.look_around.find((e) => e.place_id === element.id);
        if (look) {
          element.img_link = look.img_link;
        } else {
          element.img_link = element.main_image.S400;
        }
      });
      let zones = await getZonesList(selected.value);
      this.setState({
        selectedArea: selected,
        top_menu: homeData.top_menu,
        highlight: highlight,
        zones: zones,
      });

      let tags = homeData.local_food.concat(homeData.activities);
      let tagModel = await getTagModelList(tags, locale);
      this.setState({
        foods: tagModel.filter((e) => homeData.local_food.includes(e.id)),
        activities: tagModel.filter((e) => homeData.activities.includes(e.id)),
      });
      let blogs = await getNewestBlogs(selected.value, locale);
      this.setState({
        blogs: blogs,
      });
      let journey = await getRecommendTripLimit(selected.value, 4);
      this.setState({
        journey: journey,
      });
    }
  }

  render() {
    let { area, locale } = this.props;

    return (
      <div className="provicne-content">
        <ProvinceHeader selectedArea={this.state.selectedArea}></ProvinceHeader>
        {this.state.weather ? (
          <div className="page-box bg-blue">
            <div className="content-box weather-box">
              <div className="weather-temperature">
                <div className="weather-icon">
                  <img
                    src={WeatherTool.getWeatherImageLink(
                      this.state.weather.icon
                    )}
                    alt="weather-img"
                  />
                  <p className="weather-text">
                    {WeatherTool.getWeatherString(this.state.weather.icon)}
                  </p>
                </div>

                <p className="temperature-text">
                  {this.state.weather.temperature}°C
                </p>
              </div>
              <div className="weather-chance-rain">
                <p>
                  <IntlMessages id="chance_of_rain" />
                </p>
                <p>{this.state.weather.precipProbability}%</p>
              </div>
              <div className="weather-wind">
                <p>
                  <IntlMessages id="weather_wind" />
                </p>
                <p>
                  {WeatherTool.windDirection(this.state.weather.windBearing)}{' '}
                  {this.state.weather.windSpeed}m/s
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="page-box bg-blue">
            <div className="content-box weather-box loading">
              <div className="weather-temperature">
                <div className="weather-icon">
                  <div className="shimmer shimmer-cicle"></div>
                  <p className="text-shimmer weather-text">Loading</p>
                </div>

                <p className="temperature-text text-shimmer">Loading</p>
              </div>
              <div className="weather-chance-rain">
                <p>
                  <IntlMessages id="chance_of_rain" />
                </p>
                <p className="text-shimmer">Loading</p>
              </div>
              <div className="weather-wind">
                <p>
                  <IntlMessages id="weather_wind" />
                </p>
                <p className="text-shimmer">loading</p>
              </div>
            </div>
          </div>
        )}
        {this.state.top_menu ? (
          <div className="page-box">
            <div className="content-box menu-box">
              {this.state.top_menu.map((e, i) => (
                <div className="menu-item" key={`menu-item-${i}`} onClick={() => window.location.href = '/category/' + e.value + "?zone=" + this.state.selectedArea.value}>
                  <img
                    src={'/' + e.image.replace('icon/base', 'icons')}
                    alt="menu-img"
                  />
                  <p className="menu-title">
                    <IntlMessages id={e.name} />
                  </p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="page-box">
            <div className="content-box menu-box loading">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((e, i) => (
                <div className="menu-item" key={`menu-item-${i}`}>
                  <div className="shimmer shimmer-cicle"></div>
                  <p className="menu-title text-shimmer">Loading</p>
                </div>
              ))}
            </div>
          </div>
        )}
        <hr className="page-divider grey-bg" />
        <div className="content-box ">
          <div className="map-link">
            <div className="link-img">
              <img src="/assets/img/map-icon.png" alt="link-icon" />
            </div>
            <div className="link-text">
              <IntlMessages id="home.area-map-text" />
            </div>
            <div className="link-go">
              <IntlMessages id="home.area-map-go" /> <FaArrowRight />
            </div>
          </div>
        </div>
        <hr className="page-divider grey-bg" />
        <div className="content-box">
          <div className="app-area-content">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.intercity-transit"></IntlMessages>
              </h4>
            </div>
            <div className="transit-tabs">
              {interTransit.map((e, i) => (
                <div className="tab-item" key={`intercity-tab-${i}`}>
                  <img
                    className="tab-img"
                    src={e.image}
                    alt={`tab-item-${i}`}
                    onClick={() => {
                      if (e.link.includes("?")) {
                        window.location.href = e.link + `&departure=${this.state.selectedArea.slug}`;
                      } else {
                        window.location.href = e.link + `?departure=${this.state.selectedArea.slug}`;
                      }

                    }}
                  />
                  <p className="tab-name">
                    <IntlMessages id={e.name}></IntlMessages>
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="app-area-content">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.intracity-transit"></IntlMessages>
              </h4>
            </div>
            {this.state.selectedArea && (
              <div className="transit-tabs">
                {intraTransit
                  .filter((g) => this.state.selectedArea[g.id])
                  .map((e, i) => (
                    <div className="tab-item" key={`intracity-tab-${i}`}>
                      <img
                        className="tab-img"
                        src={e.image}
                        alt={`tab-item-${i}`}
                      />
                      <p className="tab-name">
                        <IntlMessages id={e.name}></IntlMessages>
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <hr className="page-divider grey-bg" />
        <div className="content-box">
          <div className="content-box-header">
            <h4 className="page-title">
              <IntlMessages id="home.area-title"></IntlMessages>
            </h4>
          </div>
          <ScrollContainer className="app-area-list scroll-container">
            {area.length > 0 ?
              area.filter((e) => e.id !== this.state.selectedArea?.id)
                .map((e, index) => {
                  return <div
                    key={`app-image-${index}`}
                    className="app-area-item"
                    onClick={() => {
                      window.location.href = '/province/' + e.id;
                    }}
                  >
                    <img alt={e.id} src={e.img_link} effect="blur" />
                    <p className="area-title">{getLocaleName(e)}</p>
                  </div>;
                }) :
              <div className="app-area-list empty-list">
                {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                  <div
                    key={`app-empty-image-${ind}`}
                    className="shimmer shimmer-box"
                  ></div>
                ))}
              </div>
            }
          </ScrollContainer>

        </div>
        <hr className="page-divider grey-bg" />
        <div className="content-box">
          <div className="content-box-header">
            <h4 className="page-title">
              <IntlMessages id="highlight_must_see"></IntlMessages>
            </h4>
          </div>
          {this.state.highlight ? (
            <GlideComponent
              settings={highlighTSettings}
              className="highlight-list"
            >
              {this.state.highlight
                .filter((e) => e.id !== this.state.selectedArea?.id)
                .map((e, index) => (
                  <div
                    key={`app-image-${index}`}
                    className="highlight-item"
                    onClick={() => {
                      window.location.href = '/place/detail/' + e.id;
                    }}
                  >
                    <img alt={e.id} src={e.img_link} effect="blur" />
                    <p className="highlight-title">{e.name}</p>
                  </div>
                ))}
            </GlideComponent>
          ) : (
            <div className="highlight-list empty-list">
              {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                <div
                  key={`app-empty-image-${ind}`}
                  className="shimmer shimmer-box"
                ></div>
              ))}
            </div>
          )}
        </div>
        <hr className="page-divider grey-bg" />
        <div className="content-box">
          <div className="content-box-header">
            <h4 className="page-title">
              <IntlMessages id="area_in"></IntlMessages> ({' '}
              {getLocaleName(this.state.selectedArea, locale)} )
            </h4>
          </div>
          <div className="zone-content">
            {this.state.zones ? (
              <div className="zone-list empty-list">
                {this.state.zones.map((e, ind) => (
                  <div key={`zone-image-${ind}`} className="zone-item" onClick={() => {
                    window.location.href = `/zones/map/${this.state.selectedArea?.id}?zone=${e.id}`;
                  }}>
                    <img src={e.image_square} alt="area-img" />
                    <p className="zone-name">{getLocaleName(e, locale)}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="zone-list empty-list">
                {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                  <div
                    key={`zone-image-${ind}`}
                    className="zone-item shimmer shimmer-box"
                  ></div>
                ))}
              </div>
            )}
          </div>
        </div>
        <hr className="page-divider grey-bg" />
        <div className="content-box">
          <div className="content-box-sub-header">
            <h4 className="page-title">
              <IntlMessages id="must_eat"></IntlMessages>
            </h4>
            <p className="page-sub-title">
              #One day, #Culture, #hill-tribes, #Cafe, #Walking
            </p>
          </div>
          <div className="zone-content">
            {this.state.selectedArea && this.state.foods ? (
              <div className="food-list empty-list">
                {this.state.foods.map((e, ind) => (
                  <div key={`food-image-${ind}`} className="food-item" onClick={() => {
                    window.location.href = "/tag/" + e.id + "?zone=" + this.state.selectedArea?.value;
                  }}>
                    <img
                      src={e.image_zone[this.state.selectedArea.value]}
                      alt="food-img"
                    />
                    <p className="food-name">{e.name}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="food-list empty-list">
                {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                  <div
                    key={`food-image-${ind}`}
                    className="food-item shimmer shimmer-box"
                  ></div>
                ))}
              </div>
            )}
          </div>
        </div>
        <hr className="page-divider grey-bg" />
        <div className="content-box do-tags-box">
          <div className="content-box-header">
            <h4 className="page-title">
              <IntlMessages id="home_best_things_to_do" /> ({' '}
              {getLocaleName(this.state.selectedArea, locale)} )
            </h4>
          </div>
          {this.state.selectedArea && this.state.activities ? (
            <div className="do-tags-list ">
              {this.state.activities.map((e, ind) => (
                <div key={`tag-image-${ind}`} className="tag-item" onClick={() => {
                  window.location.href = "/tag/" + e.id + "?zone=" + this.state.selectedArea?.value;
                }}>
                  <img
                    src={e.image_zone[this.state.selectedArea.value]}
                    alt="tag-img"
                  />
                  <p className="tag-name">{e.name}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="do-tags-list empty-list">
              {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                <div
                  key={`highlight-image-${ind}`}
                  className="tag-item shimmer shimmer-box"
                ></div>
              ))}
            </div>
          )}
        </div>
        <hr className="page-divider grey-bg" />

        <div className="content-box do-tags-box">
          <div className="content-box-header">
            <h4 className="page-title">
              <IntlMessages id="recommend_reading"></IntlMessages>
            </h4>
            <div className="page-link">
              <a href={`/blog/list?zone=${this.state.selectedArea?.value}`} className="see_more-btn">
                <IntlMessages id="general.see-more"></IntlMessages>
              </a>
            </div>
          </div>
          {this.state.blogs ? (
            <div className="blogs-list ">
              {this.state.blogs.map((e, ind) => (
                <div key={`blog-image-${ind}`} className="blog-item" onClick={
                  () => {
                    window.location.href = '/blog/detail/' + e.id;
                  }
                }>
                  <img src={e.cover} alt="blog-img" />
                  <div className="blog-content">
                    <p className="blog-name">{e.title}</p>
                    <p className="blog-description">
                      <i className="icomoon-nearby"></i> {e.place?.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="blogs-list empty-list">
              {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                <div key={`blog-image-${ind}`} className="blog-item">
                  <div className="shimmer"></div>
                  <div className="blog-content">
                    <p className="blog-name text-shimmer">Loading</p>
                    <p className="blog-description text-shimmer">Loading</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <hr className="page-divider grey-bg" />
        <div className="content-box">
          <div className="journey-box">
            <div className="content-box-sub-header-flex">
              <div className="title-box">
                <h4 className="page-title">
                  <IntlMessages id="home.cultural-jurney"></IntlMessages>
                </h4>
                <p className="page-sub-title">
                  <IntlMessages id="home.cultural-jurney-tags"></IntlMessages>
                </p>
              </div>
              <div className="page-header-link">
                <a href={"/trip/list?zone=" + this.state.selectedArea?.value} className="see_more-btn">
                  <IntlMessages id="general.see-more" />
                </a>
              </div>
            </div>


            <ScrollContainer className="journey-list scroll-container">
              {this.state.journey != null ?
                this.state.journey
                  .map((e, index) => {
                    return <div key={`journey-item-${index}`} className="journey-item"
                      onClick={() => {
                        window.location.href = '/trip/detail/' + e.id;
                      }}>
                      <img
                        alt={e.id}
                        src={locale === 'th' ? e.photo : e.photo_en}
                        effect="blur"
                      />
                      <div className="journey-item-info">
                        <p className="joury-title">{getLocaleTitle(e, locale)}</p>
                        <p className="joury-info">{getLocaleDetail(e, locale)}</p>
                      </div>
                    </div>;
                  }) :
                <div className="journey-list empty-list">
                  {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                    <div
                      key={`app-empty-image-${ind}`}
                      className="shimmer shimmer-box"
                    ></div>
                  ))}
                </div>
              }
            </ScrollContainer>

          </div>
        </div>
        <Footer selectedIndex="1"></Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.language;
  const { area } = state.area;
  return { locale, area };
};

const mapActionsToProps = {};

export default withNavigateHook(
  connect(mapStateToProps, mapActionsToProps)(ProvicneContent)
);
