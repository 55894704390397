import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import rootReducer from './reducers';
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.scss';

// render(
//   <Provider store={rootReducer}>
//     <App />
//   </Provider>,
//   document.getElementById('root')
// );
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
  <Provider store={rootReducer}>
    <App />
  </Provider>
  // </StrictMode>
);
