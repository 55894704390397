import { firestore } from './Firebase';
import { splitArrayByNo } from './Tools';

const blogURL = '/united/blog';
const hoemURL = 'united/setup/home';
const placeURL = '/united/place';
const tagsURL = '/united/tags';
const travelURL = 'united/travel/trip';
const travelRouteURL = 'united/travel/trip_route';
const zoneAreaURL = 'united/setup/zone';
const appAreaURL = 'data/sandbox/area';
const categoryURL = "data/sandbox/category";
const tagModelURL = "/united/tags";
const placeNoteURL = "/setup/place_note";
const reviewURL = "united/spot/reviews";
const oneTwoDestinationsURL = "data/12GO/destintaion";
const stationPointURL = "/united/station_point";

export const getHomeData = async (home) => {
  try {
    // console.log(id,state);
    let value = await firestore.doc(`${hoemURL}/${home}`).get();
    return value.data();
  } catch (e) {
    console.log(e);
    return [];
  }
};
export const getTypeAppArea = async (type) => {
  try {
    // console.log(id,state);
    let query = firestore.collection(appAreaURL).orderBy('position', 'asc');
    query = query.where(type, '==', true);
    let value = await query.get();
    return value.docs.map((e) => e.data());
  } catch (e) {
    console.log(e);
    return [];
  }
};
export const getPlaceList = async (list, locale) => {
  try {
    if (list.length > 10) {
      let newList = splitArrayByNo(list, 10);
      let result = [];
      for (const key in newList) {
        let value = await firestore
          .collection(`${locale}${placeURL}`)
          .where('id', 'in', newList[key])
          .get();
        let valData = value.docs.map((g) => g.data());
        result = result.concat(valData);
      }
      result.sort(function (a, b) {
        return list.indexOf(a.id) - list.indexOf(b.id);
      });
      return result;
    } else {
      let value = await firestore
        .collection(`${locale}${placeURL}`)
        .where('id', 'in', list)
        .get();
      let result = value.docs.map((g) => g.data());
      result.sort(function (a, b) {
        return list.indexOf(a.id) - list.indexOf(b.id);
      });
      return result;
    }
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getTagModelList = async (list, locale) => {
  try {
    if (list.length > 10) {
      let newList = splitArrayByNo(list, 10);
      let result = [];
      for (const key in newList) {
        let value = await firestore
          .collection(`${locale}${tagsURL}`)
          .where('id', 'in', newList[key])
          .get();
        let valData = value.docs.map((g) => g.data());
        result = result.concat(valData);
      }
      result.sort(function (a, b) {
        return list.indexOf(a.id) - list.indexOf(b.id);
      });
      return result;
    } else {
      let value = await firestore
        .collection(`${locale}${tagsURL}`)
        .where('id', 'in', list)
        .get();
      let result = value.docs.map((g) => g.data());
      result.sort(function (a, b) {
        return list.indexOf(a.id) - list.indexOf(b.id);
      });
      return result;
    }
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getRecommendTripLimit = async (zone, limitNo) => {
  try {
    let query = firestore
      .collection(travelURL)
      .orderBy('position', 'asc')
      .limit(limitNo);
    if (zone !== 'sandbox') {
      query = query.where('zone', '==', zone);
    }
    let value = await query.get();
    return value.docs.map((g) => g.data());
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getTripById = async (id) => {
  try {
    let query = firestore
      .collection(travelURL)
      .doc(id);

    let value = await query.get();
    if (value.exists) {
      return value.data();
    }

  } catch (e) {
    console.log(e);
  }
  return null;
};
export const getTripRouteById = async (id) => {
  try {
    let query = firestore
      .collection(travelRouteURL)
      .doc(id);

    let value = await query.get();
    if (value.exists) {
      return value.data();
    }

  } catch (e) {
    console.log(e);
  }
  return null;
};
export const getZonesList = async (zone) => {
  try {
    let query = firestore
      .collection(zoneAreaURL)
      .orderBy('order_position', 'asc');
    if (zone !== 'sandbox') {
      query = query.where('zone', '==', zone);
    }
    let value = await query.get();
    return value.docs.map((g) => g.data());
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getNewestBlogs = async (zone, locale) => {
  try {
    let query = firestore
      .collection(`${locale}${blogURL}`)
      .where('qualify', '==', 1)
      .orderBy('date', 'desc')
      .limit(4);
    if (zone && zone !== 'sandbox') {
      query = query.where('zone', '==', zone);
    }
    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs.map((g) => g.data());
    }
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getBlogViewsList = async (zone, locale, last) => {
  try {
    let query = firestore
      .collection(`${locale}${blogURL}`)
      .where('qualify', '==', 1)
      .orderBy('views', 'desc')
      .orderBy('id', 'asc')
      .limit(12);
    if (zone && zone !== 'sandbox') {
      query = query.where('zone', '==', zone);
    }
    if (last != null) {
      query = query.startAfter(last.views, last.id);
    }

    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs.map((g) => g.data());
    }
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getCategoryById = async (id) => {
  try {
    let query = firestore
      .collection(`${categoryURL}`)
      .doc(id);
    let value = await query.get();
    if (value.exists) {
      return value.data();
    }
    return null;

  } catch (e) {
    console.log(e);
  }
};
export const getTagById = async (tagId, locale) => {
  try {
    let query = firestore
      .collection(`${locale}${tagModelURL}`)
      .doc(tagId);
    let value = await query.get();
    if (value.exists) {
      return value.data();
    }
    return null;

  } catch (e) {
    console.log(e);
  }
};
export const getSubCategories = async (categoryId) => {
  try {
    let query = firestore
      .collection(`${categoryURL}`)
      .where('parent', '==', categoryId)
      .orderBy('order', 'asc');
    let value = await query.get();
    return value.docs.map((g) => g.data());
  } catch (e) {
    console.log(e);
  }
  return [];
};

export const getBlogById = async (id, locale) => {
  let query = firestore
    .collection(`${locale}${blogURL}`).doc(id)
  let value = await query.get();
  if (value.exists) {
    return value.data();
  }
  return null;

}
export const getPlaceById = async (id, locale) => {
  let query = firestore
    .collection(`${locale}${placeURL}`).doc(id)
  let value = await query.get();
  if (value.exists) {
    return value.data();
  }
  return null;

}


export const getPlaceImages = async (zone, key) => {
  try {
    let query = firestore
      .collection(`images/${zone}/${key}`);
    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs.map((g) => g.data());
    }
    return [];
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getPlaceNote = async (id, locale) => {
  try {
    let query = firestore
      .collection(`${locale}${placeNoteURL}`)
      .where('place.id', '==', id);
    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs[0].data();
    }
    return null;
  } catch (e) {
    console.log(e);
  }
  return null;
};
export const getPlaceBlogs = async (id, locale) => {
  try {
    let query = firestore
      .collection(`${locale}${blogURL}`)
      .where('place.id', '==', id)
      .orderBy('views', 'desc');

    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs.map((g) => g.data());
    }
    return [];
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getPlaceReview = async (id) => {
  try {
    let query = firestore
      .collection(`${reviewURL}`)
      .where('place_id', '==', id)
      .orderBy('date', 'desc')
      .limit(5);

    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs.map((g) => g.data());
    }
    return [];
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const get12GoDestination = async (type) => {
  try {
    let query = firestore
      .collection(`${oneTwoDestinationsURL}`)
      ;
    if (type === 'airport') {
      query = query.where('airport', '==', true);
    } else if (type === 'express_bus') {
      query = query.where('bus', '==', true);
    } else if (type === 'ferry') {
      query = query.where('ferry', '==', true);
    } else if (type === 'mini_van') {
      query = query.where('van', '==', true);
    }
    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs.map((g) => g.data());
    }
    return [];
  } catch (e) {
    console.log(e);
  }
  return [];

}
export const getZonePlace = async (zone, code, locale, last) => {
  try {
    let query = firestore
      .collection(`${locale}${placeURL}`)
      .where('active', '==', true)
      .where('zone', '==', zone)
      .where('custom', '==', code)
      .limit(18)
      .orderBy('id', 'asc');
    if (last != null) {
      query = query.startAfter(last.id);
    }

    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs.map((g) => g.data());
    }
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getZoneById = async (id) => {
  try {
    let query = firestore
      .collection(`${zoneAreaURL}`).doc(id)
    let value = await query.get();
    if (value.exists) {
      return value.data();
    }
    return null;
  } catch (e) {
    console.log(e);
  }
  return null;

};
export const getRecommendTripsList = async (zone, locale, last) => {
  try {
    let query = firestore
      .collection(travelURL)
      .where('active', '==', true)
      .orderBy('position', 'asc')
      .orderBy('id', 'asc')
      .limit(12);
    if (zone && zone !== 'sandbox') {
      query = query.where('zone', '==', zone);
    }
    if (last != null) {
      query = query.startAfter(last.position, last.id);
    }

    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs.map((g) => g.data());
    }
  } catch (e) {
    console.log(e);
  }
  return [];
};
export const getAllBTSStationPoints = async (locale) => {
  try {
    let query = firestore
      .collection(`${locale}${stationPointURL}`)
    let value = await query.get();
    if (value.docs.length > 0) {
      return value.docs.map((g) => g.data());
    }
  } catch (e) {
    console.log(e);
  }
  return [];
}