import React from 'react';
import { connect } from 'react-redux';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { getBlogById } from '../../helper/FirestoreApi';
import { withNavigateHook } from '../../helper/Tools';

class PlaceDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: null
        };
    }
    async componentDidMount() {
        let { locale } = this.props;
        let { blogId } = this.props.params;
        let blog = await getBlogById(blogId, locale);
        this.setState({
            blog: blog
        });
    }

    render() {
        return <div className="blog-detail-page">
            <Header isBack={true} title={this.state.blog?.title} />
            <div className='blog-information' dangerouslySetInnerHTML={{ __html: this.state.blog?.body }}>
            </div>
            <Footer selectedIndex="1"></Footer>
        </div>;
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(
    connect(mapStateToProps, mapActionsToProps)(PlaceDetail)
);
