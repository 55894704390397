import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogDetail from './blog/BlogDetail';
import BlogList from './blog/BlogList';
import { ComingSoonPage } from './ComingSoonPage';
import Error from './error';
import Home from './home/home';
import Privacy from './home/privacy';
import Category from './place/Category';
import PlaceDetail from './place/PlaceDetail';
import Search from './place/Search';
import TagList from './place/TagList';
import Province from './province/Province';
import ProvinceZone from './province/ProvinceZone';
import ZoneDetail from './province/ZoneDetail';
import Airplane from './transport/Airplane';
import MetroMap from './transport/MetroMap';
import Transport from './transport/Transport';
import TripDetail from './trip/TripDetail';
import TripList from './trip/TripList';
import TripMap from './trip/TripMap';

const MainLayout = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/province/:appid" element={<Province />} />

        <Route path="/zones/map/:appid" element={<ProvinceZone />} />
        <Route path="/zones/detail/:zoneId" element={<ZoneDetail />} />

        <Route path="/category/:categoryId" element={<Category />} />
        <Route path="/tag/:tagId" element={<TagList />} />

        <Route path="/place/detail/:placeId" element={<PlaceDetail />} />
        <Route path="/place/search" element={<Search />} />

        <Route path="/blog/list" element={<BlogList />} />
        <Route path="/blog/detail/:blogId" element={<BlogDetail />} />

        <Route path="/trip/list" element={<TripList />} />
        <Route path="/trip/detail/:tripId" element={<TripDetail />} />
        <Route path="/trip/map/:tripId" element={<TripMap />} />

        <Route path="/transit/airplane" element={<Airplane />} />
        <Route path="/transit/method" element={<Transport />} />

        <Route path="/metro/map" element={<MetroMap />} />

        <Route path="/error" element={<Error />} />
        <Route path="/comingSoon" element={<ComingSoonPage />} />
      </Routes>
    </Router>
  );
};

export default MainLayout;
