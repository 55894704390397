import React from 'react';
import { connect } from 'react-redux';
import { getGetParams, getLocaleDescription, getLocaleName, getOpenCloseTime, withNavigateHook } from '../../helper/Tools';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCategoryById, getSubCategories } from '../../helper/FirestoreApi';
import IntlMessages from '../../components/common/intlMessages';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { algoliaSearch } from '../../helper/algolia';
import { noImage800 } from '../../helper/defaultValues';

const current = new Date();


class Category extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            subCategories: null,
            category: null,
            selectedTab: null,
            page: 1,
            isEnd: false,
            isLoad: false,
            zone: 'sandbox'
        };
    }
    async componentDidMount() {
        let { categoryId } = this.props.params;
        let category = await getCategoryById(categoryId);
        let subCategories = await getSubCategories(categoryId);
        var parts = getGetParams();
        if (parts.zone !== undefined) {
            this.setState({
                'category': category,
                'zone': parts.zone,
                'subCategories': subCategories
            });
            this.refresh(categoryId, parts.zone)

        } else {
            this.setState({
                'category': category,
                'subCategories': subCategories
            });
            this.refresh(categoryId, 'sandbox')

        }
    }
    fetchData(category, zone) {
        if (this.state.isLoad) {
            return;
        }
        let filterText = `active:true AND _tags:${category}`;
        if (zone !== 'sandbox') {
            filterText += ` AND zone:${zone}`;
        }

        this.setState({
            isLoad: true
        })
        algoliaSearch.search('', {
            filters: filterText,
            hitsPerPage: 18,
            page: this.state.page,
        }).then(({ hits, nbHits }) => {
            if (hits !== undefined) {
                hits.forEach((e) => {
                    e.open = this.openHours(e);
                });
                this.setState({
                    isLoad: false,
                    page: this.state.page + 1,
                    isEnd: hits.length < 18,
                    items: this.state.items.concat(hits)
                });
            } else {
                this.setState({
                    isLoad: false
                });
            }
        });

    }
    refresh(category, zone) {
        if (this.state.isLoad) {
            return;
        }
        this.setState({
            isLoad: true
        })

        let filterText = `active:true AND _tags:${category}`;
        if (zone !== 'sandbox') {
            filterText += ` AND zone:${zone}`;
        }
        algoliaSearch.search('', {
            filters: filterText,
            hitsPerPage: 18,
            page: 0,
        }).then(({ hits, nbHits }) => {
            if (hits !== undefined) {
                hits.forEach((e) => {
                    e.open = this.openHours(e);
                });
                this.setState({
                    isLoad: false,
                    page: 1,
                    isEnd: hits.length < 18,
                    items: hits
                });
            } else {
                this.setState({
                    isLoad: false
                });
            }
        });
    }
    openHours(item) {
        if (item.temporaryClose) {
            return false;
        }
        var openClose = getOpenCloseTime(current, item.open_hours);

        let currenthours = Number(current.getHours() + "" + ("0" + current.getMinutes()).slice(-2));
        if (openClose.open == null || openClose.close == null) {
            return false;
        } else if (openClose.open <= currenthours &&
            openClose.close >= currenthours) {
            return true;
        } else {
            return false;
        }
    }


    render() {
        let { locale } = this.props;

        return (
            <div className="default-content">
                <Header isBack={true} title={getLocaleName(this.state.category, locale)} />
                <div className='content-box catgories-tab'>
                    <div className='categories-list'>
                        <div className={`category-tab ${this.state.selectedTab === null ? 'active' : ''}`} onClick={() => {
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                            this.setState({
                                'selectedTab': null
                            });
                            this.refresh(this.state.category.id, this.state.zone);
                        }} >
                            <IntlMessages id="all-tab" />
                        </div>
                        {this.state.subCategories && this.state.subCategories
                            .map((e, i) => (<div className={`category-tab ${e.id === this.state.selectedTab ? 'active' : ''}`} key={`category-tab-${i}`} onClick={() => {
                                this.setState({
                                    'selectedTab': e.id
                                });
                                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                this.refresh(e.id, this.state.zone);

                            }}>
                                {getLocaleName(e, locale)}
                            </div>))}
                    </div>
                </div>
                <div className='content-box '>
                    <InfiniteScroll
                        className='place-list'
                        dataLength={this.state.items.length} //This is important field to render the next data
                        next={() => this.fetchData(this.state.selectedTab ? this.state.selectedTab : this.state.category?.id, this.state.zone)}
                        hasMore={!this.state.isEnd}
                        loader={<div className='text-center'><div className='spinner'><div></div><div></div><div></div></div></div>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b> <IntlMessages id="list_see_all" /></b>
                            </p>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={() => this.refresh(this.state.selectedTab ? this.state.selectedTab : this.state.category?.id, this.state.zone)}
                        pullDownToRefresh
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>
                                &#8595; <IntlMessages id="pull_down_refresh" />
                            </h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8593; <IntlMessages id="release_to_refresh" /></h3>
                        }
                    >
                        {this.state.items.map((e, i) => (
                            <div className='place-item' key={`place-item-${i}`} onClick={() => window.location.href = '/place/detail/' + e.id}>
                                <div className='place-img' >
                                    <img src={e.main_image.S400 ? e.main_image.S400 : noImage800} alt="place-img" />
                                </div>
                                <div className='place-info'>
                                    <div className='place-info-header'>
                                        <p className='place-name'>
                                            {getLocaleName(e, locale)}
                                        </p>
                                        {e.open ? <p className='shop-open'><IntlMessages id="shop_open" /></p> : <p className='shop-close'><IntlMessages id="shop_close" /></p>}
                                    </div>
                                    <div className='place-description'>
                                        {getLocaleDescription(e, locale)}
                                    </div>
                                    <div className='place-footer'>
                                        <div className='place-rate'>
                                            {[1, 2, 3, 4, 5].map((i) => {
                                                if (i <= e.rating) {
                                                    return <i className='icomoon-start-fill' key={`place-rate-${i}`}></i>
                                                } else {
                                                    return <i className='icomoon-start-empy' key={`place-rate-${i}`}></i>
                                                }
                                            })}
                                            <span>{e.rating.toFixed(1)}</span>
                                        </div>
                                        <div className='place-reviews'>
                                            {e.review_no} <IntlMessages id="reviews" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div >
                <Footer selectedIndex="1"></Footer>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(
    connect(mapStateToProps, mapActionsToProps)(Category)
);
