import React from 'react';
import { connect } from 'react-redux';
import { getLocaleDescription, getLocaleName, withNavigateHook } from '../../helper/Tools';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getZoneById, getZonePlace } from '../../helper/FirestoreApi';
import IntlMessages from '../../components/common/intlMessages';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { noImage800 } from '../../helper/defaultValues';
import Loading from '../../components/common/Loading';



class TagList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            zone: null,
            isEnd: false,
            isLoad: false,
        };
    }
    async componentDidMount() {

        let { zoneId } = this.props.params;
        let zone = await getZoneById(zoneId);
        this.setState({
            zone: zone,
        }, () => {
            this.refresh();
        });
    }
    fetchData() {
        if (this.state.isLoad || this.state.isEnd) {
            return;
        }
        let { locale } = this.props;

        this.setState({
            isLoad: true
        })
        var last = this.state.items.slice(-1)[0]
        getZonePlace(this.state.zone.zone, this.state.zone.zone_code, locale, last).then((value) => {
            this.setState({
                isLoad: false,
                isEnd: value.length < 18,
                items: this.state.items.concat(value)
            });
        });

    }
    refresh() {
        if (this.state.isLoad) {
            return;
        }
        this.setState({
            isLoad: true
        })

        let { locale } = this.props;

        getZonePlace(this.state.zone.zone, this.state.zone.zone_code, locale, null).then((value) => {
            this.setState({
                isLoad: false,
                isEnd: value.length < 18,
                items: value
            });
        });

    }



    render() {
        if (this.state.zone == null) {
            return <Loading></Loading>
        }
        let { locale } = this.props;

        return (
            <div className="default-content">
                <Header isBack={true} title={getLocaleName(this.state.zone, locale)} />
                {this.state.zone && <div className='tag-info'>
                    <img src={this.state.zone.image_square} className="tag-img" alt='tag-img' />
                    <p className='tag-name'>
                        {getLocaleName(this.state.zone, locale)}
                    </p>
                    <p className='tag-description'>
                        {getLocaleDescription(this.state.zone, locale)}
                    </p>
                </div>
                }
                <div className='content-box '>
                    <InfiniteScroll
                        className='place-list'
                        dataLength={this.state.items.length} //This is important field to render the next data
                        next={() => this.fetchData()}
                        hasMore={!this.state.isEnd}
                        loader={<div className='text-center'><div className='spinner'><div></div><div></div><div></div></div></div>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b> <IntlMessages id="list_see_all" /></b>
                            </p>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={() => this.refresh()}
                        pullDownToRefresh
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>
                                &#8595; <IntlMessages id="pull_down_refresh" />
                            </h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8593; <IntlMessages id="release_to_refresh" /></h3>
                        }
                    >
                        {this.state.items.map((e, i) => (
                            <div className='place-item' key={`place-item-${i}`} onClick={() => window.location.href = '/place/detail/' + e.id}>
                                <div className='place-img' >
                                    <img src={e.main_image.S400 ? e.main_image.S400 : noImage800} alt="place-img" />
                                </div>
                                <div className='place-info'>
                                    <div className='place-info-header'>
                                        <p className='place-name color-black'>
                                            {e.name}
                                        </p>
                                    </div>
                                    <div className='place-footer'>
                                        <div className='place-rate'>
                                            {[1, 2, 3, 4, 5].map((i) => {
                                                if (i <= e.rating) {
                                                    return <i className='icomoon-start-fill' key={`place-rate-${i}`}></i>
                                                } else {
                                                    return <i className='icomoon-start-empy' key={`place-rate-${i}`}></i>
                                                }
                                            })}
                                            <span>{e.rating.toFixed(1)}</span>
                                        </div>
                                        <div className='place-reviews'>
                                            {e.review_no} <IntlMessages id="reviews" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div >
                <Footer selectedIndex="1"></Footer>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(
    connect(mapStateToProps, mapActionsToProps)(TagList)
);
