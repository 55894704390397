import React from "react";


class Loading extends React.Component {
    render() {

        return (
            <div id="loader"></div>
        );
    }
}

export default Loading;
