module.exports = {
  /* 01.General */
  'general.menu-place': 'PLACES',
  'general.menu-ticket': 'TICKETS',
  'general.menu-transit': 'TRANSIT',
  'general.menu-shopping': 'SHOPPING',
  'general.menu-3d': '3D & VR',
  'general.place-select-region': 'Please select a region',
  'general.area-thailand': 'Thailand',
  'general.header-search-places': 'Search Places',
  'general.header-search-activities': 'Search Activities',
  'general.header-transit-from': 'From',
  'general.header-transit-to': 'To',
  'general.header-shopping-looking-for': 'What are you looking for?',
  'general.header-search-shopping': 'Search Products or shop',
  'general.header-vr-title': 'Cultural Heritage 3D of Thailand ',
  'general.header-search-btn': 'Search',
  'general.see-more': 'See More',
  'general.bottom_menu_home': 'Home',
  'general.bottom_translate': 'Translate',
  'general.bottom_menu_my_trip': 'My Trip',
  'general.bottom_menu_booking': 'Booking',
  'general.bottom_menu_cart': 'Cart',
  'general.bottom_menu_account': 'Account',

  'home.area-title': 'Top Destinations in Thailand',
  'home.area-map-text': 'Explore key destinations on map',
  'home.area-map-go': 'Go',
  'home.highlight-title': 'Highlight',
  'home.explore-title': 'Explore Attractions',
  'home.explore-places': 'Places',
  'home.activities': 'Activities',
  'home.kokoa_tab_ticket': 'Pass/Tickets',
  'home.kokoa_tab_restaurant': 'Restaurants',
  'home.kokoa_tab_activities': 'Activities',
  'home.kokoa_tab_beauty': 'Spa/Beauty',
  'home.kokoa_tab_tour': 'Tour',
  'home.kokoa_tab_golf': 'Golf',
  'home.best_things_to_do': 'Best Things To Do',
  'home.cultural-jurney': 'Trip recommend',

  'home.cultural-jurney-tags':
    '#One day, #Culture, #hill-tribes, #Cafe, #Walking ',
  'home.intercity-transit': 'Intercity Transit',
  'home.intracity-transit': 'Intracity Transit',
  'home.intracity-transit-des': 'Please Select City',

  'home.transport_demestic_flight': 'Domestic Flight',
  'home.transport_trains': 'Trains',
  'home.transport_express_bus': 'Express Bus',
  'home.transport_mini_van': 'Mini Van',
  'home.transport_songthaew': 'Songthaew',
  'home.transport_taxi': 'Taxi',
  'home.transport_boat_ferry': 'Boat/Ferry',
  'home.transport_mrt_bts': 'MRT/BTS',
  'home.transport_bus': 'Bus',
  'home.transport_map': 'Transit Map',
  'home.transit_home_travel_island': 'Travel to an Island?',
  'home.transit_home_travel_zone': 'Where to go?',
  'home.transit_home_travel_zone_price_from': 'From',
  'home.ticket_home_incredible_destinations': 'Incredible destinations',
  'home.ticket_home_explore_activities': 'Explore Activities',
  'home.transit_home_travel_bangkok': 'Travel Bangkok',
  'home.transit_home_travel_bangkok_caption': 'On MRT/BTS Line',
  'home.transit_home_travel_pattaya': 'Travel Pattaya',
  'home.transit_home_travel_pattaya_caption': 'On Songthaew Routes',
  'home.transit_home_travel_chiangmai': 'Travel Chiang Mai',
  'home.transit_home_travel_chiangmai_caption': 'On Bus/Songthaew Routes',
  'home.transit_home_travel_phuket': 'Travel Phuket',
  'home.transit_home_travel_phuket_caption': 'On Songthaew Routes',
  'home.vr_home_sub_title':
    'Cultural Heritage Administration for Next Generation on Evolution',
  'home.vr_home_description1':
    'With the advancements in digital technology, the way people consume information and data on cultural heritage has changed significantly.',
  'home.vr_home_description2':
    'A cultural heritage that creates future values ​​through digital',
  'home.vr_home_description3':
    'Scientific conservation management and constant collaboration in virtual space with digital twin',
  'home.vr_home_explore_heritages': 'Explore 3D Heritages of Thailand',
  "chance_of_rain": "Chance of rain",
  "weather_wind": "Wind",
  "weather-clear-day": "Clear Day",
  "weather-clear-night": "Clear Night",
  "weather-cloudy": "Cloudy",
  "weather-partly-cloudy-day": "Partly Cloudy Day",
  "weather-partly-cloudy-night": "Partly Cloudy Night",
  "weather-rain": "Rain",
  "weather-snow": "Snow",
  "weather-sleet": "Sleet",
  "weather-wind": "Wind",
  "weather-fog": "Fog",
  "weather-thunderstorm": "Thunderstorm",
  "menu_sightseeing": "Sightseeing",
  "menu_eating": "Eating",
  "menu_shopping": "Shopping",
  "menu_experience": "Experience",
  "menu_cafe_desert": "Cafe & Desert",
  "menu_accommodation": "Accommodation",
  "menu_public_office": "Public Office",
  "menu_information": "Information",
  "map_link_text": "Explore key destinations on map",
  "highlight_must_see": "#Highlight",
  "area_in": "Area",
  "must_eat": "Must Eat",
  "home_best_things_to_do": "Best Things to Do",

  "recommend_reading": "Recommend Reading",
  "all-tab": "All",
  "pull_down_refresh": "Pull down to refresh",
  "list_see_all": "You have seen it all",
  "release_to_refresh": "Release to refresh",
  "shop_close": "Close",
  "shop_open": "Open",
  "reviews": "Reviews",
  "reviewed_by": "Reviewed By",
  "evaluate_very_good": "Very Good",
  "evaluate_good": "Good",
  "evaluate_normal": "Normal",
  "evaluate_poor": "Poor",
  "evaluate_bad": "Bad",
  "no_reviews": "No Reviews",
  "count_reviews": "Reviews",
  "count_blogs": "Blog(s)",
  "call": "Call",
  "route": "Route",
  "bookmark": "Bookmark",
  "share": "Share",

  "place_tab_info": "Info",
  "place_tab_blog": "Blog",
  "place_tab_review": "Review",
  "weekday_mon": "Monday",
  "weekday_tue": "Tuesday",
  "weekday_wed": "Wednesday",
  "weekday_thur": "Thursday",
  "weekday_fri": "Friday",
  "weekday_sat": "Saturday",
  "weekday_sun": "Sunday",
  "how_to_get_there": "How to get there",
  "blog_review": "Blog Review",
  "no_blog_yet": "No blog yet",
  "rating_reviews": "Ratings & Reviews",
  "airplane": "Domestic Flight",
  "transport_bus": "Express Bus",
  "transport_van": "Mini Van",
  "transport_train": "Train",
  "transport_ferry": "Boat/Ferry",
  "flight_departure": "Departure",
  "flight_arrival": "Arrival",
  "onetwo_bangkok": "Bangkok",
  "onetwo_ayutthaya": "Ayutthaya",
  "onetwo_trat": "Trat",
  "onetwo_ubonratchathani": "Ubon Ratchathani",
  "onetwo_udonthani": "Udon Thani",
  "onetwo_chiangrai": "Chiang Rai",
  "onetwo_chiangmai": "Chiang Mai",
  "onetwo_sukhothai": "Sukhothai",
  "onetwo_chumphon": "Chumphon",
  "onetwo_ranong": "Ranong",
  "onetwo_suratthani": "Surat thani",
  "onetwo_krabi": "Krabi",
  "onetwo_nakhonsithammarat": "Nakhon Si Thammarat",
  "onetwo_trang": "Trang",
  "onetwo_songkhla": "Songkhla",
  "onetwo_pattani": "Pattani",
  "onetwo_phuket": "Phuket",
  "onetwo_kohsamui": "Koh Samui",
  "onetwo_huahin": "Hua Hin",
  "onetwo_pattaya": "Pattaya",
  "onetwo_booking": "Booking",
  "onetwo_nakhonnayok": "Nakhon Nayok",
  "onetwo_samutprakan": "Samut Prakan",
  "onetwo_nonthaburi": "Nonthaburi",
  "onetwo_samutsakhon": "Samut Sakhon",
  "onetwo_samutsongkhram": "Samut Songkhram",
  "onetwo_nakhonpathom": "Nakhon Pathom",
  "onetwo_singburi": "Singburi",
  "onetwo_angthong": "Angthong",
  "onetwo_pathumthani": "Pathumthani",
  "onetwo_saraburi": "Saraburi",
  "onetwo_chachoengsao": "Chachoengsao",
  "onetwo_chonburi": "Chonburi",
  "onetwo_chanthaburi": "Chanthaburi",
  "onetwo_prachinburi": "Prachinburi",
  "onetwo_lopburi": "Lopburi",
  "onetwo_nakhonratchasima": "Nakhon Ratchasima",
  "onetwo_buriram": "Buriram",
  "onetwo_surin": "Surin",
  "onetwo_sisaket": "Sisaket",
  "onetwo_yasothon": "Yasothon",
  "onetwo_roiet": "Roi Et",
  "onetwo_mahasarakham": "Mahasarakham",
  "onetwo_khonkaen": "Khon Kaen",
  "onetwo_chaiyaphum": "Chaiyaphum",
  "onetwo_nakhonphanom": "Nakhon Phanom",
  "onetwo_kalasin": "Kalasin",
  "onetwo_mukdahan": "Mukdahan",
  "onetwo_sakonnakhon": "Sakon Nakhon",
  "onetwo_nongkhai": "Nong Khai",
  "onetwo_loei": "Loei",
  "onetwo_phitsanulok": "Phitsanulok",
  "onetwo_uttaradit": "Uttaradit",
  "onetwo_nan": "Nan",
  "onetwo_phrae": "Phrae",
  "onetwo_phayao": "Phayao",
  "onetwo_lampang": "Lampang",
  "onetwo_maehongson": "Mae Hong Son",
  "onetwo_lamphun": "Lamphun",
  "onetwo_tak": "Tak",
  "onetwo_kamphaengphet": "Kamphaeng Phet",
  "onetwo_phichit": "Phichit",
  "onetwo_nakhonsawan": "Nakhon Sawan",
  "onetwo_phetchabun": "Phetchabun",
  "onetwo_uthaithani": "Uthai Thani",
  "onetwo_chainat": "Chainat",
  "onetwo_kanchanaburi": "Kanchanaburi",
  "onetwo_suphanburi": "Suphanburi",
  "onetwo_ratchaburi": "Ratchaburi",
  "onetwo_rayong": "Rayong",
  "onetwo_phetchaburi": "Phetchaburi",
  "onetwo_prachuapkhirikhan": "Prachuap Khiri Khan",
  "onetwo_phangnga": "Phang Nga",
  "onetwo_phatthalung": "Phatthalung",
  "onetwo_satun": "Satun",
  "onetwo_narathiwat": "Narathiwat",
  "onetwo_hatyai": "Hat Yai",
  "onetwo_kohlanta": "Koh Lanta",
  "onetwo_kohphangan": "Koh Phangan",
  "onetwo_kohtao": "Koh Tao",
  "onetwo_kohphiphi": "Koh Phi Phi",
  "onetwo_khaolak": "Khao Lak",
  "onetwo_pranburi": "Pranburi",
  "onetwo_chaam": "Cha Am",
  "onetwo_nongbualamphu": "Nong Bua Lamphu",
  "onetwo_amnatcharoen": "Amnat Charoen",
  "onetwo_sakaeo": "Sa Kaeo",
  "onetwo_kohchang": "Koh Chang",
  "onetwo_yala": "Yala",
  "onetwo_kohlarn": "Koh Larn",
  "onetwo_kohlipe": "Koh Lipe",
  "onetwo_donsak": "Donsak",
  "onetwo_kohyaoyai": "Koh Yao Yai",
  "onetwo_kohyaonoi": "Koh Yao Noi",
  "general.zone_all": "All",
  "zone.look_around": "Look Around",
  "genral.in_construction": "This page is currently under construction",
  "genral.service_by_app": "If you want to use the service, please press the button to use the app.",
  "general.coming_soon": "Coming Soon",
  "general.open_app": "Open the app",
  "general.see_on_map": "See on Map",
  "mrt_bts": "BTS/MRT",
};
