import React from 'react';
import { connect } from 'react-redux';
import { getGetParams, withNavigateHook } from '../../helper/Tools';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getRecommendTripsList } from '../../helper/FirestoreApi';
import IntlMessages from '../../components/common/intlMessages';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';


class TripList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isEnd: false,
            isLoad: false,
            zone: 'sandbox'
        };
    }
    async componentDidMount() {

        var parts = getGetParams();
        if (parts.zone !== undefined) {
            this.setState({
                'zone': parts.zone,
            }, () => {
                this.refresh();
            });


        } else {
            this.refresh()
        }
    }
    fetchData() {
        let { locale } = this.props;

        if (this.state.isLoad) {
            return;
        }
        this.setState({
            isLoad: true
        })
        var last = this.state.items.slice(-1)[0]

        getRecommendTripsList(this.state.zone, locale, last).then((value) => {
            this.setState({
                isLoad: false,
                isEnd: value.length < 12,
                items: this.state.items.concat(value)
            });
        });
    }
    refresh() {
        let { locale } = this.props;

        if (this.state.isLoad) {
            return;
        }
        this.setState({
            isLoad: true
        })
        getRecommendTripsList(this.state.zone, locale, null).then((value) => {
            this.setState({
                isLoad: false,
                isEnd: value.length < 12,
                items: value
            });
        });
    }


    render() {
        const { locale } = this.props;
        return (
            <div className="default-content">
                <Header isBack={true} title={<IntlMessages id="recommend_reading" />} />
                <div className='content-box '>
                    <InfiniteScroll
                        className='recommend-trip-list'
                        dataLength={this.state.items.length} //This is important field to render the next data
                        next={() => this.fetchData()}
                        hasMore={!this.state.isEnd}
                        loader={<div className='text-center'><div className='spinner'><div></div><div></div><div></div></div></div>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b> <IntlMessages id="list_see_all" /></b>
                            </p>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={() => this.refresh()}
                        pullDownToRefresh
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>
                                &#8595; <IntlMessages id="pull_down_refresh" />
                            </h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8593; <IntlMessages id="release_to_refresh" /></h3>
                        }
                    >
                        {this.state.items.map((e, i) => (
                            <div className='trip-item' key={`blog-item-${i}`} >
                                <div className='trip-img' >
                                    <b className='trip-marker'>{i + 1}</b>
                                    <img
                                        alt="blog-img" onClick={
                                            () => {
                                                window.location.href = '/trip/detail/' + e.id;
                                            }
                                        }
                                        src={locale === 'th' ? e.photo : e.photo_en}
                                        effect="blur"
                                    />
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div >
                <Footer selectedIndex="1"></Footer>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(
    connect(mapStateToProps, mapActionsToProps)(TripList)
);
