import React from 'react';
import IntlMessages from './intlMessages';

const footer_navigate = [
  {
    id: 1,
    name: 'general.bottom_menu_home',
    icon: 'icomoon-footer-home',
    link: '/',
  },
  {
    id: 2,
    name: 'general.bottom_translate',
    icon: 'icomoon-footer-language',
    link: '/comingSoon',
  },
  {
    id: 3,
    name: 'general.bottom_menu_cart',
    icon: 'icomoon-footer-shopping-cart',
    link: '/comingSoon',
  },
  {
    id: 4,
    name: 'general.bottom_menu_my_trip',
    icon: 'icomoon-footer-my-trip',
    link: '/comingSoon',
  },
  {
    id: 5,
    name: 'general.bottom_menu_booking',
    icon: 'icomoon-footer-bookmark',
    link: '/comingSoon',
  },
  {
    id: 6,
    name: 'general.bottom_menu_account',
    icon: 'icomoon-footer-account',
    link: '/comingSoon',
  },

];

class Footer extends React.Component {
  render() {
    const { selectedIndex } = this.props;

    return (
      <footer className="footer">
        <div className="content-box">
          <div className="footer-tabs">
            {footer_navigate.map((e, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div
                onClick={() => window.location.href = e.link}
                className={
                  'footer-tab-item ' +
                  (e.id.toString() === selectedIndex ? 'active' : '')
                }
                key={`footer-key-${index}`}
              >
                <i className={e.icon}></i>
                <p className="tab-item-title">
                  <IntlMessages id={e.name} />
                </p>
              </div>
            ))}
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
