import React from "react";
import { connect } from "react-redux";
import Loading from "../../components/common/Loading";
import Footer from "../../components/common/Footer";
import { getLocaleDetail, getLocaleName, getLocaleTitle, withNavigateHook } from "../../helper/Tools";
import { getTripById } from "../../helper/FirestoreApi";
import Header from "../../components/common/Header";
import { noImage400 } from "../../helper/defaultValues";
import IntlMessages from "../../components/common/intlMessages";



class TripDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trip: null,
        };
    }
    async componentDidMount() {
        let { tripId } = this.props.params;
        let trip = await getTripById(tripId)
        this.setState({
            'trip': trip
        });
    }
    getLocaleText(area, locale) {
        if (area === null) {
            return '';
        }

        switch (locale) {
            case 'en':
                return area.text_en;
            case 'th':
                return area.text_th;
            default:
                return area.text_en;
        }
    };
    render() {
        if (this.state.trip === null) {
            return <Loading />
        }
        const { locale } = this.props;
        return (<div className="default-content trip-content">
            <Header isBack={true} title={getLocaleTitle(this.state.trip, locale)} />
            <div className="content-box trip-detail-page">
                <h2 className="trip-title">{getLocaleTitle(this.state.trip, locale)}</h2>
                <p className="trip-detail">{getLocaleDetail(this.state.trip, locale)}</p>
                <div className="course-box">
                    <div className="course-header">
                        <img src={this.state.trip.map} alt="course-img" />
                    </div>
                    <div className="course-list">
                        <ul className="sessions">
                            {this.state.trip.course.map((e, i) => {
                                return <li key={`cour-items-${i}`}>
                                    <div className="trip-course-item">
                                        <div className="trip-course-content">
                                            <div className="time">{getLocaleName(e.place, locale)}</div>
                                            <p className="course-detail">{this.getLocaleText(e, locale)}</p>
                                            <p className="course-duration"><i className='material-icons'>directions_run</i>{e.min.includes('m') ? e.min : (e.min + "m")} {e.distance}</p>
                                        </div>
                                        <div className="trip-course-img">
                                            <img alt="course-img" src={e.place.main_image.S400 !== '' ? e.place.main_image.S400 : noImage400} onClick={() => window.location.href = '/place/detail/' + e.place.id} />
                                        </div>
                                    </div>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
                <div className="trip-actions">
                    <a href={"/trip/map/" + this.state.trip.id} className="btn btn-block btn-primary">
                        <IntlMessages id="general.see_on_map" />
                    </a>
                </div>

            </div>
            <Footer selectedIndex="1"></Footer>
        </div>)
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(
    connect(mapStateToProps, mapActionsToProps)(TripDetail)
);
