import React from 'react';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../redux/lang/actions';
import IntlMessages from '../../../components/common/intlMessages';
import GlideComponent from '../../../components/carousel/GlideComponent';
import {
  getLocaleDetail,
  getLocaleName,
  getLocaleTitle,
} from '../../../helper/Tools';
import { FaArrowRight } from 'react-icons/fa';
import {
  getHomeData,
  getPlaceList,
  getRecommendTripLimit,
  getTagModelList,
} from '../../../helper/FirestoreApi';
import ScrollContainer from 'react-indiana-drag-scroll'

const subBannerSetting = {
  type: 'carousel',
  gap: 10,
  perView: 1,
  startAt: 0,
  hideNav: true,
  hideDots: false,
  hideFooter: false,
  breakpoints: {
    500: { perView: 1, gap: 10 },
    700: { perView: 2, gap: 10 },
    9999: { perView: 2, gap: 10 },
  },
};

// const activityTabs = [
//     {
//         id: 'Gjr7iyyKUP1xDHwzrahj',
//         name: "home.kokoa_tab_ticket",
//         image: "assets/icons/kokoa-ticket.png",
//         link: "/activity/category",
//     },
//     {
//         id: 'ZdyujPzotqsvP1GKQb29',
//         name: "home.kokoa_tab_restaurant",
//         image: "assets/icons/kokoa-restaurant.png",
//         link: "/activity/category"
//     },
//     {
//         id: 'tWwxtPXGnmfZCP62h1Vx',
//         name: "home.kokoa_tab_activities",
//         image: "assets/icons/kokoa-activities.png",
//         link: "/activity/category"
//     },
//     {
//         id: '4F60KeEiZpDsV1xEnC3R',
//         name: "home.kokoa_tab_beauty",
//         image: "assets/icons/kokoa-beauty.png",
//         link: "/activity/category"
//     },
//     {
//         id: 'OrWQjhDqWDURHPjQwTTy',
//         name: "home.kokoa_tab_tour",
//         image: "assets/icons/kokoa-tour.png",
//         link: "/activity/category"
//     },
//     {
//         id: 'kdXBUBGU5Jm0xj6WmZcp',
//         name: "home.kokoa_tab_golf",
//         image: "assets/icons/kokoa-golf.png",
//         link: "/activity/category"
//     },
// ];

class HomePlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heritage: [],
      explore: [],
      sub_banner: [],
      activitis: [],
      doTags: [],
      journey: [],
      isComplete: false,
    };
  }
  async componentDidMount() {
    const { locale } = this.props;
    let homeData = await getHomeData('sandbox');
    this.setState({
      explore: homeData.explore,
      sub_banner: homeData.sub_banner,
    });
    let heritage = await getPlaceList(
      homeData.look_around.map((e) => e.place_id),
      locale
    );
    this.setState({
      heritage: heritage,
    });
    // fetch(`https://us-central1-kokoa-1a871.cloudfunctions.net/webApi/getRecentlyActivities?limit=10&lang=${locale}`)
    //     .then(res => res.json())
    //     .then(
    //         (result) => {

    //             this.setState({
    //                 activitis: result.activities
    //             });
    //         },
    //         (error) => {
    //             console.log(error)
    //         }
    //     );
    let doTags = await getTagModelList(homeData.activities, locale);
    this.setState({
      doTags: doTags,
    });
    let journey = await getRecommendTripLimit('sandbox', 4);
    // console.log(journey);
    this.setState({
      journey: journey,
    });

    // let result = await kokoaIndex.search(searchKeyword, {
    //     filters: filterText,
    //     hitsPerPage: pageHits,
    //     page: page,
    // });
  }
  componentDidUpdate() {
    const { areas } = this.props;
    if (
      !this.state.isComplete &&
      this.state.heritage.length > 0 &&
      areas.length > 0
    ) {
      for (let item in this.state.heritage) {
        const element = this.state.heritage[item];
        element.area = areas.find((e) => e.value === element.zone);
      }
      for (let item in this.state.explore) {
        const element = this.state.explore[item];
        element.area = areas.find((e) => e.value === element.value);
      }
      this.setState({
        heritage: this.state.heritage,
        explore: this.state.explore,
        isComplete: true,
      });
    }
  }

  render() {
    const { locale, areas } = this.props;

    return (
      <div className="home-page page-clear">
        <div className="content-box">
          <div className="page-divider"></div>
          <div className="app-area-content">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.area-title"></IntlMessages>
              </h4>
            </div>
            <ScrollContainer className="app-area-list scroll-container">
              {areas.length > 0 ?
                areas.map((e, index) => {
                  return <div
                    key={`app-image-${index}`}
                    className="app-area-item"
                    onClick={() => {
                      window.location.href = '/province/' + e.id;
                    }}
                  >
                    <img alt={e.id} src={e.img_link} effect="blur" />
                    <p className="area-title">{getLocaleName(e)}</p>
                  </div>;
                }) :
                <div className="app-area-list empty-list">
                  {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                    <div
                      key={`app-empty-image-${ind}`}
                      className="shimmer shimmer-box"
                    ></div>
                  ))}
                </div>
              }
            </ScrollContainer>

          </div>
          <div className="destination-map-box">
            <div className="map-box-icon">
              <img src="/assets/img/map-icon.png" alt="map-icon" />
            </div>
            <div className="map-box-text">
              <IntlMessages id="home.area-map-text" />
            </div>
            <div className="map-box-link">
              <IntlMessages id="home.area-map-go" /> <FaArrowRight />
            </div>
          </div>
          <div className="page-divider"></div>
          <div className="highlight-box">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.highlight-title"></IntlMessages>
              </h4>
            </div>
            <div className="highlight-content">
              {this.state.heritage.length > 0 ? (
                <div className="highlight-list empty-list">
                  {this.state.heritage.map((e, ind) => (
                    <div
                      key={`highlight-image-${ind}`}
                      className="highlight-item"
                      onClick={() => {
                        window.location.href = '/place/detail/' + e.id;
                      }}
                    >
                      <img src={e.main_image.S800} alt="area-img" />
                      <p className="highlight-zone">{getLocaleName(e.area)}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="highlight-list empty-list">
                  {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                    <div
                      key={`highlight-image-${ind}`}
                      className="highlight-item shimmer shimmer-box"
                    ></div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="page-divider"></div>
          <div className="explore-box">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.explore-title"></IntlMessages>
              </h4>
              <div className="page-link">
                <a href="/category/gHpn7tcdqtp4kwg44DJU" className="page-link-btn">
                  <IntlMessages id="general.see-more"></IntlMessages>
                </a>
              </div>
            </div>

            {this.state.explore.length > 0 ? (
              <ScrollContainer className="explore-list scroll-container">
                {this.state.explore.map((e, index) => {
                  return <div key={`explore-item-${index}`} className="explore-item" onClick={() => {
                    window.location.href = '/category/gHpn7tcdqtp4kwg44DJU?zone=' + e.value;
                  }}>
                    <img alt={e.id} src={e.image} effect="blur" />
                    <div className="explore-item-info">
                      <p className="zone-title">
                        <i className="icomoon-placeholder"></i>{' '}
                        {e.area != null && getLocaleName(e.area)}
                      </p>
                      <p className="zone-info">
                        <b>{e.count}</b>{' '}
                        <IntlMessages id="home.explore-places"></IntlMessages>
                      </p>
                    </div>
                  </div>
                }
                )}
              </ScrollContainer>
            ) : (
              <div className="explore-list empty-list">
                {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                  <div
                    key={`app-empty-image-${ind}`}
                    className="shimmer shimmer-box"
                  ></div>
                ))}
              </div>
            )}

          </div>
        </div>
        {this.state.sub_banner.length > 0 && (
          <div className="banner-box">
            <div className="content-box">
              <GlideComponent
                settings={subBannerSetting}
                className="subbanner-list"
              >
                {this.state.sub_banner.map((e, index) => {
                  switch (locale) {
                    case 'en':
                      return (
                        <img onClick={() => {
                          if (e.value === "OGYQT7SjUnvCCJq6dvL2") {
                            window.location.href = "https://m.kokoamall.com/home/page/1";
                          } else {
                            window.location.href = e.value;
                          }
                        }}
                          key={'sub-banner-' + index}
                          className="banner-img"
                          src={e.img_en}
                          alt={e.name}
                        />
                      );
                    case 'th':
                      return (
                        <img onClick={() => {
                          if (e.value === "OGYQT7SjUnvCCJq6dvL2") {
                            window.location.href = "https://m.kokoamall.com/home/page/1";
                          } else {
                            window.location.href = e.value;
                          }
                        }}
                          key={'sub-banner-' + index}
                          className="banner-img"
                          src={e.img_th}
                          alt={e.name}
                        />
                      );
                    default:
                      return <div></div>;
                  }
                })}
              </GlideComponent>
            </div>
          </div>
        )}
        <div className="content-box">
          {/* <div className='activity-box'>
                        <div className='content-box-header'>
                            <h4 className='page-title'><IntlMessages id='home.activities'></IntlMessages></h4>
                            <div className='page-link'>
                                <a href='/' className='page-link-btn'><IntlMessages id='general.see-more'></IntlMessages></a>
                            </div>
                        </div>
                        <div className='activity-tabs'>
                            {activityTabs.map((e, i) => (
                                <div className='tab-item' key={"activity-tab-" + i}><img className='tab-item-img' src={e.image} alt='tab-icon'></img>
                                    <p className=''><IntlMessages id={e.name}></IntlMessages></p></div>
                            ))}
                        </div>
                        {this.state.activitis.length > 0 ? <GlideComponent settings={slideSettings2} className="acitity-list">
                            {this.state.activitis.map((e, index) => (
                                <div className='activity-item-box' key={"acitivity-item-" + index}>
                                    <div className='activity-img'>
                                        <img src={e.cover_image.S400} alt={e.name} />
                                    </div>
                                    <div className='activity-info'>
                                        <p className='activity-sale-tag'>{Math.ceil((e.price - e.sale_price) / e.price * 100)}% OFF</p>
                                        <p className='activity-name'>{e.name}</p>
                                        <div className='review-info'>
                                            <div className='review-rate'><i className='icomoon-start-fill'></i> {e.rating.toFixed(1)}</div>
                                            <div className='review-rate'>{e.review_no} reviews</div>
                                        </div>
                                        <p className='activity-price'>฿ {currencyFormat(e.sale_price)}</p>
                                    </div>
                                </div>
                            ))}
                        </GlideComponent> : <div className='explore-list empty-list'>
                            {[0, 1, 2, 3, 4, 5].map((e, ind) => (<div key={`app-empty-image-${ind}`} className='shimmer shimmer-box'>
                            </div>))}
                        </div>
                        }
                    </div> */}
          <div className="do-tags-box">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.best_things_to_do"></IntlMessages>
              </h4>
            </div>
            {this.state.doTags.length > 0 ? (
              <div className="do-tags-list ">
                {this.state.doTags.map((e, ind) => (
                  <div key={`tag-image-${ind}`} className="tag-item" onClick={() => {
                    window.location.href = "/tag/" + e.id;
                  }}>
                    <img src={e.image_zone.sendbox} alt="tag-img" />
                    <p className="tag-name">{e.name}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="do-tags-list empty-list">
                {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                  <div
                    key={`highlight-image-${ind}`}
                    className="tag-item shimmer shimmer-box"
                  ></div>
                ))}
              </div>
            )}
          </div>
          <div className="journey-box">

            <div className="content-box-sub-header-flex">
              <div className="title-box">
                <h4 className="page-title">
                  <IntlMessages id="home.cultural-jurney"></IntlMessages>
                </h4>
                <p className="page-sub-title">
                  <IntlMessages id="home.cultural-jurney-tags"></IntlMessages>
                </p>
              </div>
              <div className="page-header-link">
                <a href={"/trip/list"} className="see_more-btn">
                  <IntlMessages id="general.see-more" />
                </a>
              </div>
            </div>



            <ScrollContainer className="journey-list scroll-container">
              {this.state.journey.length > 0 ?
                this.state.journey
                  .map((e, index) => {
                    return <div key={`journey-item-${index}`} className="journey-item" onClick={() => {
                      window.location.href = '/trip/detail/' + e.id;
                    }}>
                      <img
                        alt={e.id}
                        src={locale === 'th' ? e.photo : e.photo_en}
                        effect="blur"
                      />
                      <div className="journey-item-info">
                        <p className="joury-title">{getLocaleTitle(e, locale)}</p>
                        <p className="joury-info">{getLocaleDetail(e, locale)}</p>
                      </div>
                    </div>;
                  }) :
                <div className="journey-list empty-list">
                  {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                    <div
                      key={`app-empty-image-${ind}`}
                      className="shimmer shimmer-box"
                    ></div>
                  ))}
                </div>
              }
            </ScrollContainer>


          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.language;
  return { locale };
};

const mapDispatchToProps = (dispatch) => ({
  changLang: (val) => {
    dispatch(changeLanguage(val));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePlace);
