import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { firestore } from './helper/Firebase';
import AppLocale from './lang';
import { updateArea } from './redux/area/actions';
import MainLayout from './views/layout';
// import AppLocale from './lang';
import { isMobile } from 'react-device-detect';
import { getRedirectDate, setRedirectDate } from './helper/localStorage';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedArea: null,
      areas: [],
    };
  }
  async componentDidMount() {
    const { updated, updateArea } = this.props;
    let yourDate = new Date();
    let text = yourDate.toISOString().split('T')[0];
    let redirected = await getRedirectDate();
    if (updated !== text) {
      let areas = await this.getAppArea();
      updateArea(areas);
      window.location.reload();
    }
    if (redirected !== text) {
      await setRedirectDate();
      if (isMobile) {
        window.location.href = "https://treasurethailand.page.link/bjYi";
      }
    }
  }

  async getAppArea() {
    try {
      let valueDocs = await firestore
        .collection('data/sandbox/area')
        .orderBy('position')
        .get();
      return valueDocs.docs.map((g) => g.data());
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];
    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <div className="page-content">
          <MainLayout />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.language;
  const { area, updated } = state.area;
  return { locale, area, updated };
};
const mapActionsToProps = (dispatch) => ({
  updateArea: (val) => {
    dispatch(updateArea(val));
  },
});

export default connect(mapStateToProps, mapActionsToProps)(App);
