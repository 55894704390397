import React from 'react';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../redux/lang/actions';
import IntlMessages from '../../../components/common/intlMessages';
import GlideComponent from '../../../components/carousel/GlideComponent';
import { getHomeData } from '../../../helper/FirestoreApi';
import { currencyFormat, getLocaleName } from '../../../helper/Tools';
import { Dropdown } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { interTransit, intraTransit } from '../../../helper/defaultValues';

const slideSettings = {
  type: 'carousel',
  gap: 10,
  perView: 2.4,
  startAt: 0,
  hideNav: true,
  hideFooter: true,
  hideDots: true,
  breakpoints: {
    500: { perView: 2.4, gap: 10 },
    700: { perView: 3.4, gap: 10 },
    9999: { perView: 4.4, gap: 10 },
  },
};

const exploreTravel = [
  {
    img_link: 'assets/icons/explore-bts.png',
    name: 'home.transit_home_travel_bangkok',
    caption: 'home.transit_home_travel_bangkok_caption',
    link: '',
  },
  {
    img_link: 'assets/icons/explore-songthaew.png',
    name: 'home.transit_home_travel_pattaya',
    caption: 'home.transit_home_travel_pattaya_caption',
    link: '',
  },
  {
    img_link: 'assets/icons/explore-bus-songtheaw.png',
    name: 'home.transit_home_travel_chiangmai',
    caption: 'home.transit_home_travel_chiangmai_caption',
    link: '',
  },
  {
    img_link: 'assets/icons/expolore-phuket.png',
    name: 'home.transit_home_travel_phuket',
    caption: 'home.transit_home_travel_phuket_caption',
    link: '',
  },
];

class HomeTransit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isComplete: false,
      areas: [],
      crreuntCity: null,
      travel: [],
      zones: [],
    };
  }
  async componentDidMount() {
    const { areas } = this.props;
    let transit = areas.filter((e) => e.transit === true);
    let homeData = await getHomeData('home_transit');
    this.setState({
      areas: transit,
      crreuntCity: transit[0],
      travel: homeData['travel_islands'],
      zones: homeData['travel_zones'],
    });

    // let result = await kokoaIndex.search(searchKeyword, {
    //     filters: filterText,
    //     hitsPerPage: pageHits,
    //     page: page,
    // });
  }
  getLocaleFrom(value) {
    const { locale } = this.props;
    switch (locale) {
      case 'en':
        return value.from_en;
      case 'th':
        return value.from_th;
      case 'ko':
        return value.from_ko;
      default:
        break;
    }
  }
  getLocaleTo(value) {
    const { locale } = this.props;
    switch (locale) {
      case 'en':
        return value.to_en;
      case 'th':
        return value.to_th;
      case 'ko':
        return value.to_ko;
      default:
        break;
    }
  }
  render() {
    const { areas, locale } = this.props;
    return (
      <div className="home-page page-clear">
        <div className="content-box">
          <div className="app-area-content">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.intercity-transit"></IntlMessages>
              </h4>
            </div>
            <div className="transit-tabs">
              {interTransit.map((e, i) => (
                <div className="tab-item" key={`intercity-tab-${i}`}>
                  <img
                    className="tab-img"
                    src={e.image}
                    alt={`tab-item-${i}`}
                    onClick={
                      () => {
                        window.location.href = e.link;
                      }
                    }
                  />
                  <p className="tab-name">
                    <IntlMessages id={e.name}></IntlMessages>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="content-box">
          <div className="app-area-content">
            <div className="content-box-sub-header-flex">
              <div className="title-box">
                <h4 className="page-title">
                  <IntlMessages id="home.intracity-transit"></IntlMessages>
                </h4>
                <p className="page-sub-title">
                  <IntlMessages id="home.intracity-transit-des" />
                </p>
              </div>
              <div className="page-header-link">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="content-box-selector"
                    id="dropdown-basic"
                  >
                    {this.state.crreuntCity === null ? (
                      <IntlMessages id="general.area-thailand" />
                    ) : (
                      getLocaleName(this.state.crreuntCity, locale)
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {this.state.areas.map((e, i) => (
                      <Dropdown.Item
                        key={'content-box-area-' + i}
                        href="#"
                        onClick={() => {
                          this.setState({
                            crreuntCity: e,
                          });
                        }}
                      >
                        {getLocaleName(e, locale)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {this.state.crreuntCity != null && (
            <div className="transit-tabs">
              {intraTransit
                .filter((g) => this.state.crreuntCity[g.id])
                .map((e, i) => (
                  <div className="tab-item" key={`intracity-tab-${i}`}>
                    <img
                      className="tab-img"
                      src={e.image}
                      alt={`tab-item-${i}`}
                    />
                    <p className="tab-name">
                      <IntlMessages id={e.name}></IntlMessages>
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="content-box">
          <img
            className="transit-banner"
            src="/assets/img/smart-route-search-banner.png"
            alt="banner"
          />
        </div>

        <div className="content-box">
          <div className="explore-content">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.ticket_home_explore_activities"></IntlMessages>
              </h4>
            </div>
          </div>
          <div className="transit-explore-list">
            {exploreTravel.map((e) => (
              <div className="explore-tab">
                <img src={'/' + e.img_link} alt="explore-img" />
                <p className="explore-title">
                  <IntlMessages id={e.name} />
                </p>
                <p>
                  <IntlMessages id={e.caption} />
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="content-box">
          <div className="app-area-content">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.transit_home_travel_island"></IntlMessages>
              </h4>
            </div>
            {this.state.travel.length > 0 ? (
              <GlideComponent settings={slideSettings} className="travel-list">
                {this.state.travel.map((e, index) => (
                  <div
                    key={`travel-item-${index}`}
                    className="travel-item"
                    onClick={() => {
                      window.location.href = `https://treasure-thailand.12go.asia/${locale}/travel/${e.from_slug}/${e.to_slug}`;
                    }}
                  >
                    <img alt={e.id} src={e.image} effect="blur" />
                    <div className="travel-item-info">
                      <span className="travel-from">
                        {this.getLocaleFrom(e)}
                      </span>
                      <FaArrowRight />
                      <span className="travel-to">{this.getLocaleTo(e)}</span>
                    </div>
                  </div>
                ))}
              </GlideComponent>
            ) : (
              <div className="travel-list empty-list">
                {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                  <div
                    key={`app-empty-image-${ind}`}
                    className="shimmer shimmer-box"
                  ></div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="content-box">
          <div className="app-area-content">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.transit_home_travel_zone"></IntlMessages>
              </h4>
            </div>
          </div>
          <div className="travel-zone-list">
            {this.state.zones.length > 0
              ? this.state.zones.map((e, index) => (
                <div
                  key={`travel-zone-${index}`}
                  className="travel-zone-item"
                  onClick={() => {
                    window.location.href = `https://treasure-thailand.12go.asia/${locale}/travel/${e.from_slug}/${e.to_slug}`;
                  }}
                >
                  <img alt={e.id} src={e.image} effect="blur" />
                  <div className="travel-zone-item-info">
                    <div className="travel-zone-left">
                      {' '}
                      <span className="travel-from">
                        {this.getLocaleFrom(e)}
                      </span>
                      <FaArrowRight />
                      <span className="travel-to">{this.getLocaleTo(e)}</span>
                    </div>
                    <div>
                      <IntlMessages id="home.transit_home_travel_zone_price_from" />{' '}
                      ฿ {currencyFormat(e.price, 0)}
                    </div>
                  </div>
                </div>
              ))
              : [0, 1, 2, 3, 4, 5].map((e, ind) => (
                <div
                  key={`travel-zone-empty-image-${ind}`}
                  className="shimmer shimmer-box"
                ></div>
              ))}
          </div>
          <div className="app-area-content">
            <div className="content-box-header">
              <h4 className="page-title">
                <IntlMessages id="home.ticket_home_incredible_destinations"></IntlMessages>
              </h4>
            </div>
            {areas.length > 0 ? (
              <GlideComponent
                settings={slideSettings}
                className="app-area-list"
              >
                {areas.map((e, index) => (
                  <div key={`app-image-${index}`} className="app-area-item">
                    <img alt={e.id} src={e.img_link} effect="blur" />
                    <p className="area-title">{getLocaleName(e)}</p>
                  </div>
                ))}
              </GlideComponent>
            ) : (
              <div className="app-area-list empty-list">
                {[0, 1, 2, 3, 4, 5].map((e, ind) => (
                  <div
                    key={`app-empty-image-${ind}`}
                    className="shimmer shimmer-box"
                  ></div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.language;
  return { locale };
};

const mapDispatchToProps = (dispatch) => ({
  changLang: (val) => {
    dispatch(changeLanguage(val));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeTransit);
