import {
  getCurrentLanguage,
  setCurrentLanguage,
} from '../../helper/localStorage';
import { CHANGE_LANGUAGE } from './constants';

const initialState = {
  locale: getCurrentLanguage(),
};

export default function languageReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      setCurrentLanguage(action.text);
      return {
        locale: action.text,
      };
    default:
      return state;
  }
}
