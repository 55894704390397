import React from "react";
import { connect } from "react-redux";
import Loading from "../../components/common/Loading";
import Footer from "../../components/common/Footer";
import { centerPoint, getLocaleTitle, withNavigateHook } from "../../helper/Tools";
import { getPlaceList, getTripById, getTripRouteById } from "../../helper/FirestoreApi";
import Header from "../../components/common/Header";
import { GoogleApiWrapper, InfoWindow, Map, Marker, Polyline } from "google-maps-react";
import { googleKey, lineColorList } from "../../helper/defaultValues";



class TripMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trip: null,
            trip_route: null,
            places: null,
            center: null,
            selected: null,
        };
    }
    async componentDidMount() {
        const { locale } = this.props;
        let { tripId } = this.props.params;
        let trip = await getTripById(tripId);
        let trip_route = await getTripRouteById(tripId);
        console.log(trip_route);
        let places = await getPlaceList(trip.course.map((e) => e.place.id), locale);

        let center = centerPoint(places.map((e) => e.location.geopoint))
        this.setState({
            'trip': trip,
            'trip_route': trip_route,
            'places': places,
            'center': center,
        });
    }
    getLocaleText(area, locale) {
        if (area === null) {
            return '';
        }
        switch (locale) {
            case 'en':
                return area.text_en;
            case 'th':
                return area.text_th;
            default:
                return area.text_en;
        }
    };
    render() {
        let { locale, google } = this.props;

        if (this.state.trip === null) {
            return <Loading />
        }
        let lat, lng = 0;
        lat = this.state.center.latitude;
        lng = this.state.center.longitude;
        if (this.state.selected) {
            lat = this.state.selected.location.geopoint?.latitude;
            lng = this.state.selected.location.geopoint?.longitude;
        }

        return (<div className="default-content trip-map-content">
            <Header isBack={true} title={getLocaleTitle(this.state.trip, locale)} />
            <Map google={google}
                className="trip-map"
                initialCenter={{
                    lat: lat,
                    lng: lng
                }}
                clickableIcons={false}
                zoom={14}>
                {this.state.places &&
                    this.state.places.filter((e) => e.id !== this.state.selected?.id).map((p, i) => {
                        return <Marker
                            label={{
                                text: `${p.name}`,
                                className: 'marker-label'
                            }}
                            position={{ lat: p.location.geopoint.latitude, lng: p.location.geopoint.longitude }}
                            icon={{
                                url: `/assets/img/markers/marker-${i + 1}.png`,
                                labelOrigin: new google.maps.Point(105, 24)
                            }}
                            onClick={() => this.setState({
                                selected: p
                            })}
                        />;
                    })
                }
                {this.state.trip_route && this.state.places.filter((e) => e.id !== this.state.places[0].id).map((e, i) =>
                    <Polyline key={`route_key-${i}`}
                        path={this.state.trip_route.route[e.id].points}
                        strokeColor={lineColorList[(i + 1) % 10]}
                        strokeWeight={4} />)}


                <InfoWindow
                    position={{ lat: lat, lng: lng }}
                    visible={this.state.selected !== null}>
                    <div className='info-content'>
                        <div className='info-header'>
                            <div className='info-img'>{this.state.selected && <img src={this.state.selected?.main_image.S400} alt="info-img" />}</div>
                            <div className='info-detail'>
                                <p className='place-name'>{this.state.selected?.name}</p>
                                <p className='place-tag'>{this.state.selected?.tag.filter((e) => !e.includes('~')).map((e, i) => <span key={`tag-key-${i}`} className="tag-label">#{e}</span>)}</p>
                                <div className='text-end'>
                                    <a href={'/place/detail/' + this.state.selected?.id} className='zone-detail-link'>
                                        {(() => {
                                            if (locale === 'en') {
                                                return "Look Around";
                                            } else if (locale === 'th') {
                                                return "ดูสถานที่รอบๆ";
                                            }
                                        })()}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </InfoWindow>
            </Map>
            <Footer selectedIndex="1"></Footer>
        </div>)
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(GoogleApiWrapper((props) => ({
    apiKey: googleKey,
    language: 'en',
}))(connect(mapStateToProps, mapActionsToProps)(TripMap)));
