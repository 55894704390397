import React from 'react';
import { connect } from 'react-redux';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import IntlMessages from '../../components/common/intlMessages';
import { noImage800 } from '../../helper/defaultValues';
import { getPlaceBlogs, getPlaceById, getPlaceImages, getPlaceNote, getPlaceReview } from '../../helper/FirestoreApi';
import { dateString, withNavigateHook } from '../../helper/Tools';
import copy from 'copy-to-clipboard';
const dayList = ['mon', 'tue', 'wed', 'thur', 'fri', 'sat', 'sun'];

class PlaceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      place: null,
      images: null,
      note: null,
      blogs: null,
      reviews: null,
      hours: false,
    };
  }
  async componentDidMount() {
    let { locale } = this.props;
    let { placeId } = this.props.params;
    let place = await getPlaceById(placeId, locale);
    let note = await getPlaceNote(placeId, locale);
    let blogs = await getPlaceBlogs(placeId, locale);
    let images = await getPlaceImages(place.zone, placeId);
    let reviews = await getPlaceReview(placeId);
    if (place) {
      this.setState({
        place: place,
        images: images,
        note: note,
        blogs: blogs,
        reviews: reviews,
      })
    }
  }
  buildPlaceImage(images) {
    if (images.length === null || images.length === 0) {
      return <div className='place-img'><div className='main-img'><img src={noImage800} alt="place-img" /></div></div>
    } else if (images.length === 1) {
      return <div className='place-img'><div className='main-img'><img src={images[0].S800} alt="place-img" /></div></div>
    } else {
      let max_length = Math.min(images.length, 4);
      let column_text = "column_4";
      if (max_length < 4) {
        column_text = `column_${max_length}`;
      }
      return <div className='place-img'>
        <div className='main-img'>
          <img src={images[0].S800} alt="place-img" />
        </div>
        <div className='sub-img'>
          {images.slice(1, max_length).map((e, i) =>
          (<div className={`sub-place-img ${column_text}`} key={`sub-img-${i}`}>
            <img src={e.S400} alt="place-img" />
            {i === 2 && images.length > 4 && <a href={'/place/images/' + this.state.place.id} className='img-more'><i className="material-icons">more_horiz</i></a>}
          </div>)
          )}
        </div>
      </div>

    }
  }
  evaluationRating(rating) {
    if (rating > 4.0) {
      return <IntlMessages id='evaluate_very_good' />;
    } else if (rating > 3.0) {
      return <IntlMessages id='evaluate_good' />;
    } else if (rating > 2.0) {
      return <IntlMessages id='evaluate_normal' />;
    } else if (rating > 1.0) {
      return <IntlMessages id='evaluate_poor' />;
    } else if (rating > 0.0) {
      return <IntlMessages id='evaluate_bad' />;
    } else {
      return <IntlMessages id='no_reviews' />;
    }
  }
  openHours(openhours) {
    let newMap = dayList.reduce(function (rv, x) {
      (rv[openhours[x].open + "-" + openhours[x].close] = rv[openhours[x].open + "-" + openhours[x].close] || []).push(x);
      return rv;
    }, {});
    let maxLength = 0;
    let value = '';
    Object.keys(newMap).forEach(element => {
      if (newMap[element].length > maxLength) {
        maxLength = newMap[element].length;
        value = element;
      }
    });
    if (value !== '' && newMap[value].length > 0) {
      return <p>
        <IntlMessages id={`weekday_${newMap[value][0]}`} /> - <IntlMessages id={`weekday_${newMap[value][newMap[value].length - 1]}`} />  {openhours[newMap[value][0]].open} ~ {openhours[newMap[value][0]].close}
      </p>
    }
    return "";

  }
  render() {
    // let { locale } = this.props;
    if (this.state.place === null) {
      return <div></div>;
    }
    return <div className="default-content place-content">
      <Header isBack={true} title={this.state.place?.name} />
      {this.buildPlaceImage(this.state.images)}
      <div className='content-box'>
        <div className='place-header'>
          <h2 className='place-name'>{this.state.place.name}</h2>
          <div className='place-state'>
            <div className='header-rate'>
              {this.state.place.rating.toFixed(1)}
            </div>
            <div className='header-evaluation'>
              {this.evaluationRating(this.state.place.rating)}
            </div>
            <div className='header-count'>
              <p>{this.state.place.review_no} <IntlMessages id="count_reviews" /> </p>
              <p>{this.state.blogs.length} <IntlMessages id="count_blogs" /> </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='content-box'>
        <div className='place-actions'>
          <div className='place-action-item' onClick={() => {
            window.open('tel:' + this.state.place.contact.replaceAll(' ', '').replaceAll('-', ''));
          }}>
            <i className="material-icons">phone</i>
            <p><IntlMessages id="call"></IntlMessages></p>
          </div>

          <div className='place-action-item' onClick={() => {
            if (this.state.place.place_id !== '') {
              window.open(`https://www.google.com/maps/search/?api=1&query=${this.state.place.location.geopoint._lat}%2C${this.state.place.location.geopoint._long}&query_place_id=${this.state.place.place_id}`);
            }

            window.open(`https://www.google.com/maps/place/${this.state.place.location.geopoint._lat},${this.state.place.location.geopoint._long}/@${this.state.place.location.geopoint._lat},${this.state.place.location.geopoint._long},17z`);
          }} >
            <i className="icomoon-place-route"></i>
            <p><IntlMessages id="route"></IntlMessages></p>
          </div>
          <div className='place-action-item' onClick={() => {
            if (window.sidebar && window.sidebar.addPanel) { // Firefox <23

              window.sidebar.addPanel(document.title, window.location.href, '');

            } else if (window.external && ('AddFavorite' in window.external)) { // Internet Explorer

              window.external.AddFavorite(window.location.href, document.title);

            } else { // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D

              alert('You can add this page to your bookmarks by pressing ' + (navigator.userAgent.toLowerCase().indexOf('mac') !== - 1 ? 'Command/Cmd' : 'CTRL') + ' + D on your keyboard.');

            }
            // If you have something in the `href` of your trigger
            return false;

          }}>
            <i className="material-icons">bookmark</i>
            <p><IntlMessages id="bookmark"></IntlMessages></p>
          </div>
          <div className='place-action-item' onClick={() => {
            const result = copy(window.location.href);
            if (result) {
              alert("Copy to clipboard")
            }
          }}>
            <i className="icomoon-export"></i>
            <p><IntlMessages id="share"></IntlMessages></p>
          </div>

        </div>
      </div>
      <div className='place-tab-bar'>
        <div className='place-tab-item active' onClick={() => {
          document.getElementById("place-information").scrollIntoView();
        }}>
          <IntlMessages id="place_tab_info" />
        </div>
        <div className='place-tab-item' onClick={() => {
          document.getElementById("place-blog").scrollIntoView();
        }}>
          <IntlMessages id="place_tab_blog" />
        </div>
        <div className='place-tab-item' onClick={() => {
          document.getElementById("place-reviews").scrollIntoView();
        }}>
          <IntlMessages id="place_tab_review" />
        </div>
      </div>

      <div className='content-box place-information' id='place-information'>
        <p className='place-desc'>{this.state.place.description}</p>
        <div className='place-tags'>
          {this.state.place.tag.map((e, i) => (<div className='place-tag-label' key={`tag-${i}`}>#{e}</div>))}
        </div>
        {this.state.place.open_hours &&
          <div className='place-info-box' onClick={() => {
            this.setState({
              hours: !this.state.hours
            })
          }}>
            <div className='info-icon'>
              <i className='icomoon-clock'></i>
            </div>
            <div className='info-text'>
              {this.openHours(this.state.place.open_hours)}
              {this.state.hours && <div className='open-hours'>
                {dayList.map((e, i) => {
                  return <p key={`open-hour-${i}`}><IntlMessages id={`weekday_${e}`} /> {this.state.place.open_hours[e].open} ~ {this.state.place.open_hours[e].close}</p>
                })}
              </div>}
            </div>
            <div className='info-right-icon'><i className={this.state.hours ? 'icomoon-arrow-up' : 'icomoon-arrow-bottom'}></i></div>
          </div>
        }
        {this.state.place.contact !== '' &&
          <div className='place-info-box' >
            <div className='info-icon'>
              <i className="material-icons">phone</i>
            </div>
            <div className='info-text' >
              <button onClick={() => {
                window.open('tel:' + this.state.place.contact.replaceAll(' ', '').replaceAll('-', ''));
              }}>{this.state.place.contact}</button>
            </div>
          </div>
        }
        {this.state.place.website !== '' &&
          <div className='place-info-box' >
            <div className='info-icon'>
              <i className="material-icons">language</i>
            </div>
            <div className='info-text'>
              <a href={this.state.place.website} rel="noreferrer" target="_blank">{this.state.place.website}</a>
            </div>
          </div>
        }
        <div className='place-info-box' >
          <div className='info-icon'>
            <i className="icomoon-placeholder"></i>
          </div>
          <div className='info-text'>
            <p>{this.state.place.address}</p>
          </div>
          <div className='info-right-icon'>
            <img src='/assets/img/map-img.png' alt='address-img' onClick={() => {
              if (this.state.place.place_id !== '') {
                window.open(`https://www.google.com/maps/search/?api=1&query=${this.state.place.location.geopoint._lat}%2C${this.state.place.location.geopoint._long}&query_place_id=${this.state.place.place_id}`);
              }
              window.open(`https://www.google.com/maps/place/${this.state.place.location.geopoint._lat},${this.state.place.location.geopoint._long}/@${this.state.place.location.geopoint._lat},${this.state.place.location.geopoint._long},17z`);
            }} />
          </div>
        </div>
        {this.state.note && <div className='place-note'>
          <div className='place-note-title'><img src='/assets/icons/tip-icon.png' alt='tip-img' /> {this.state.note.title}</div>
          <div className='place-note-description' dangerouslySetInnerHTML={{ __html: this.state.note?.description }}></div>
        </div>}
        <div className='how-to-there'>
          <h3 className='how-title'><IntlMessages id="how_to_get_there" /></h3>
          {this.state.place.method.length > 0 &&
            this.state.place.method.map((e) => <div className='method-box'>
              <div className='method-icon'>
                {e.type === 'metro' && <i className='material-icons'>directions_subway</i>}
                {e.type === 'songthaew' && <i className='material-icons'>airport_shuttle</i>}
                {e.type === 'bus' && <i className='material-icons'>directions_bus</i>}
                {e.type === 'ship' && <i className='material-icons'>directions_boat</i>}
                {e.type === 'boat' && <i className='material-icons'>directions_boat</i>}
              </div>
              <div className='method-info'>
                <p className='method-name'>{e.method}</p>
                <p className='method-detail'>{e.detail}</p>
              </div>
            </div>)}
        </div>
      </div>
      <div className='divider'>
      </div>
      <div className='content-box place-blog' id='place-blog'>
        <div className='blog-header-title'>
          <IntlMessages id={this.state.blogs && this.state.blogs.length > 0 ? "blog_review" : "no_blog_yet"} />
        </div>

        {this.state.blogs && this.state.blogs.length > 0 && <div className='blog-list'>
          {this.state.blogs.map((e) => {
            return <div className='blog-item'>
              <div className='blog-content'>
                <div className='blog-text'>
                  <p className='blog-title' onClick={() => {
                    window.location.href = "/blog/detail/" + e.id;
                  }}>{e.title}</p>
                  <p className='blog-description'>{e.description}</p>
                </div>
                <div className='blog-img'>
                  <img src={e.cover} alt='blog-img' />
                </div>
              </div>
              <div className='blog-author'>
                <div className='author-img'>
                  <img src={e.user.photo} alt="author-img" onClick={() => {
                    window.location.href = "/blog/detail/" + e.id;
                  }} />
                </div>
                <div className='author-info'>
                  <p className='author-name'>{e.user.name}</p>
                  <p className='author-date'>{dateString(e.date, 'd/m/Y')}</p>
                </div>
                <div className='views'>
                  <i class="material-icons">visibility</i> <span>{e.views}</span>
                </div>
              </div>
            </div>;
          })}
        </div>}
      </div>
      <div className='divider'>
      </div>
      <div className='content-box place-reviews' id='place-reviews'>
        <div className='review-header'>
          <h3 className='review-header-title'><span className='color-blue'>Treasure</span> <span><IntlMessages id="rating_reviews" /></span></h3>
          <p className='reivew-hader-rate'>
            <span className='rating'>{this.state.place.rating.toFixed(1)}</span> / 5
            {[1, 2, 3, 4, 5].map((e) => {
              if (e <= this.state.place.rating) {
                return <i className='icomoon-start-fill'></i>
              }
              return <i className='icomoon-start-empy'></i>
            })}
            <span className='review-no'>( {this.state.place.review_no} <IntlMessages id="count_reviews" /> )</span>
          </p>
        </div>
        <div className='review-list'>
          {this.state.reviews.map((e) => {
            return <div className='review-item'>
              <div className='review-header'>
                <div className='review-header-img'>
                  <img src={e.user.photo} alt="review-img" />
                </div>
                <div className='reivew-header-text'>
                  <p className='reviewer'>{e.user.name}</p>
                  <p className='rating'>
                    {[1, 2, 3, 4, 5].map((g) => {
                      if (g <= e.rate) {
                        return <i className='icomoon-start-fill'></i>
                      }
                      return <i className='icomoon-start-empy'></i>
                    })}
                    <span className='date'>{dateString(e.date, 'Y-m-d')}</span>
                  </p>
                </div>
              </div>
              <div className='review-body'>
                <p className='message'>{e.detail}</p>
                {e.images.length > 0 && <div className='review-attach'>
                  {e.images.map((g) => (<div className='attach-img' onClick={() => window.location.href = g}><img src={g} alt="attach-img" /></div>))}
                </div>}
              </div>
            </div>
          })}
        </div>

      </div>

      <Footer selectedIndex="1"></Footer>
    </div>;
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.language;
  return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(
  connect(mapStateToProps, mapActionsToProps)(PlaceDetail)
);
