import React from 'react';
import { connect } from 'react-redux';
import { getGetParams, getLocaleName, getZoneImage, withNavigateHook } from '../../helper/Tools';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getTagById } from '../../helper/FirestoreApi';
import IntlMessages from '../../components/common/intlMessages';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { algoliaSearch } from '../../helper/algolia';
import { noImage800 } from '../../helper/defaultValues';



class TagList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            tag: null,
            page: 1,
            isEnd: false,
            isLoad: false,
            zone: 'sandbox'
        };
    }
    async componentDidMount() {
        let { locale } = this.props;

        let { tagId } = this.props.params;
        let tag = await getTagById(tagId, locale);
        var parts = getGetParams();
        if (parts.zone !== undefined) {
            this.setState({
                'tag': tag,
                'zone': parts.zone,
            });
            this.refresh(tagId, parts.zone)

        } else {
            this.setState({
                'tag': tag,
            });
            this.refresh(tagId, 'sandbox')

        }
    }
    fetchData(category, zone) {
        if (this.state.isLoad) {
            return;
        }
        let filterText = `active:true AND _tags:${category}`;
        if (zone !== 'sandbox') {
            filterText += ` AND zone:${zone}`;
        }
        this.setState({
            isLoad: true
        })

        algoliaSearch.search('', {
            filters: filterText,
            hitsPerPage: 18,
            page: this.state.page,
        }).then(({ hits, nbHits }) => {
            if (hits !== undefined) {

                this.setState({
                    isLoad: false,
                    page: this.state.page + 1,
                    isEnd: hits.length < 18,
                    items: this.state.items.concat(hits)
                });
            } else {
                this.setState({
                    isLoad: false
                });
            }
        });

    }
    refresh(category, zone) {
        if (this.state.isLoad) {
            return;
        }
        this.setState({
            isLoad: true
        })

        let filterText = `active:true AND _tags:${category}`;
        if (zone !== 'sandbox') {
            filterText += ` AND zone:${zone}`;
        }

        algoliaSearch.search('', {
            filters: filterText,
            hitsPerPage: 18,
            page: 0,
        }).then(({ hits, nbHits }) => {
            if (hits !== undefined) {
                this.setState({
                    isLoad: false,
                    page: 1,
                    isEnd: hits.length < 18,
                    items: hits
                });
            } else {
                this.setState({
                    isLoad: false
                });
            }
        });
    }



    render() {
        let { locale } = this.props;

        return (
            <div className="default-content">
                <Header isBack={true} title={this.state.tag?.name} />
                {this.state.tag && <div className='tag-info'>
                    <img src={getZoneImage(this.state.tag, this.state.zone)} className="tag-img" alt='tag-img' />
                    <p className='tag-name'>
                        {this.state.tag.name}
                    </p>
                    <p className='tag-description'>
                        {this.state.tag.detail}
                    </p>
                </div>
                }
                <div className='content-box '>
                    <InfiniteScroll
                        className='place-list'
                        dataLength={this.state.items.length} //This is important field to render the next data
                        next={() => this.fetchData(this.state.tag?.id, this.state.zone)}
                        hasMore={!this.state.isEnd}
                        loader={<div className='text-center'><div className='spinner'><div></div><div></div><div></div></div></div>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b> <IntlMessages id="list_see_all" /></b>
                            </p>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={() => this.refresh(this.state.tag?.id, this.state.zone)}
                        pullDownToRefresh
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>
                                &#8595; <IntlMessages id="pull_down_refresh" />
                            </h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8593; <IntlMessages id="release_to_refresh" /></h3>
                        }
                    >
                        {this.state.items.map((e, i) => (
                            <div className='place-item' key={`place-item-${i}`} onClick={() => window.location.href = '/place/detail/' + e.id}>
                                <div className='place-img' >
                                    <img src={e.main_image.S400 ? e.main_image.S400 : noImage800} alt="place-img" />
                                </div>
                                <div className='place-info'>
                                    <div className='place-info-header'>
                                        <p className='place-name color-black'>
                                            {getLocaleName(e, locale)}
                                        </p>
                                    </div>
                                    <div className='place-footer'>
                                        <div className='place-rate'>
                                            {[1, 2, 3, 4, 5].map((i) => {
                                                if (i <= e.rating) {
                                                    return <i className='icomoon-start-fill' key={`place-rate-${i}`}></i>
                                                } else {
                                                    return <i className='icomoon-start-empy' key={`place-rate-${i}`}></i>
                                                }
                                            })}
                                            <span>{e.rating.toFixed(1)}</span>
                                        </div>
                                        <div className='place-reviews'>
                                            {e.review_no} <IntlMessages id="reviews" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div >
                <Footer selectedIndex="1"></Footer>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(
    connect(mapStateToProps, mapActionsToProps)(TagList)
);
