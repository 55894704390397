import React from 'react';
import IntlMessages from './intlMessages';

class TranslateHeader extends React.Component {
    render() {
        const { title, isBack } = this.props;

        return (
            <header className="header">
                <div className="content-box">
                    <div className='header-box'>
                        <div className='header-icon'>
                            {isBack && <span onClick={() => window.history.back()}><i className='icomoon-arrow-left'></i></span>}
                        </div>
                        <div className='header-title'><IntlMessages id={title} /> </div>
                        <div className='header-icon'>
                            <i className='icomoon-search'></i>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default TranslateHeader;