import { TransformComponent, TransformWrapper } from "@pronestor/react-zoom-pan-pinch";
import React from "react";
import ImageMapper from 'react-img-mapper';
import { connect } from "react-redux";
import IntlMessages from "../../components/common/intlMessages";
import Loading from "../../components/common/Loading";
import TranslateHeader from "../../components/common/TranslateHeader";
import { getAllBTSStationPoints } from "../../helper/FirestoreApi";

class MetroMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            points: null,
            map: 'https://firebasestorage.googleapis.com/v0/b/treasure-trip-4aa5a.appspot.com/o/metro-map%2Fmetro_map.jpeg?alt=media&token=560d4389-442d-49b5-8d33-efa40ec8fa41',
            stations: null,
            selected: null,
            fromStation: null,
            toStation: null,
        };
    }
    async componentDidMount() {
        const { locale } = this.props;

        if (this.state.points === null) {
            let stations = await getAllBTSStationPoints(locale);

            let points = stations.map((e) => {
                let dx = Math.round(400 + (e.left * 0.2), 1);
                let dy = Math.round(706 - (e.top * 0.2) - 7.8, 1);
                return {
                    name: e.id, shape: "circle", coords: [dx, dy, 10],
                };
            });
            this.setState({
                points: points,
                stations: stations,
            },);

        }

    }
    selectedPoint(e) {
        let station = this.state.stations.find((g) => g.id === e.name);
        if (station) {
            station.dx = e.center[0];
            station.dy = e.center[1];
            this.setState({
                selected: station
            });
        }
    }
    setPoint(station, type) {
        if (type === 'from') {
            document.getElementById('from-station').value = station.name;
            this.setState({
                fromStation: station,
                selected: null,
            });
        } else if (type === 'to') {
            document.getElementById('to-station').value = station.name;
            this.setState({
                toStation: station, selected: null,
            });
        }
    }


    render() {

        if (this.state.points === null) {
            return <Loading />
        }

        const MAP = {
            name: 'my-map',
            // GET JSON FROM BELOW URL AS AN EXAMPLE
            areas: this.state.points
        };

        return (<div className={"default-content metro-content"}>
            <TranslateHeader isBack={true} title={"mrt_bts"} />
            <div className="metro-header-box">
                <div className="metro-header">
                    <div className="switch-btn">
                        <i className="icomoon-exchange"></i>
                    </div>
                    <div className="metro-header-station">
                        <div className="metro-station">
                            <input name="from-station" id="from-station" placeholder="From" />
                        </div>
                        <div className="metro-station">
                            <input name="to-station" id="to-station" placeholder="To" />
                        </div>
                    </div>
                </div>
            </div>
            <TransformWrapper >
                <TransformComponent wrapperClass="metro-map-box" contentClass="metro-map-content">
                    <ImageMapper
                        src={this.state.map}
                        width={800}
                        height={1412}
                        map={MAP}
                        onClick={(e) => this.selectedPoint(e)}
                    />
                    {this.state.selected && <div className="point" style={{ 'left': this.state.selected.dx, 'top': this.state.selected.dy }}>
                        <div className="select-action-btn" onClick={() => this.setPoint(this.state.selected, 'from')}>
                            <i className="icomoon-swipe-out"></i>
                            <p><IntlMessages id="metro_from_btn" /></p>
                        </div>
                        <div className="select-action-btn" onClick={() => this.setPoint(this.state.selected, 'to')}>
                            <i className="icomoon-swipe-in"></i>
                            <p><IntlMessages id="metro_to_btn" /></p>
                        </div>
                    </div>}
                    {this.state.fromStation && <div className="marker-point" style={{ 'left': this.state.fromStation.dx, 'top': this.state.fromStation.dy }}><img src="/assets/icons/metro_from.png" /></div>}
                    {this.state.toStation && <div className="marker-point" style={{ 'left': this.state.toStation.dx, 'top': this.state.toStation.dy }}><img src="/assets/icons/metro_to.png" /></div>}
                </TransformComponent>
            </TransformWrapper>

            {this.state.selected && <div className="">
            </div>}
        </div>
        )
    }
}
const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(MetroMap);
