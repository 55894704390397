module.exports = {
  /* 01.General */
  'general.menu-place': 'สถานที่',
  'general.menu-ticket': 'จองตั๋ว',
  'general.menu-transit': 'การขนส่ง',
  'general.menu-shopping': 'ช็อปปิ้ง',
  'general.menu-3d': '3D & VR',
  'general.place-select-region': 'โปรดเลือกภูมิภาค',
  'general.area-thailand': 'ประเทศไทย',
  'general.header-search-places': 'ค้นหาสถานที่',
  'general.header-search-activities': 'ค้นหากิจกรรม',
  'general.header-transit-from': 'จาก',
  'general.header-transit-to': 'ไป',
  'general.header-shopping-looking-for': 'ประเภทผลิตภัณฑ์วัฒนธรรม',
  'general.header-search-shopping': 'ค้นหาร้านหรือผลิตภัณฑ์',
  'general.header-vr-title': 'มรดกวัฒนธรรม ของประเทศไทย แบบ 3D ',
  'general.header-search-btn': 'ค้นหา',
  'general.see-more': 'ดูเพิ่มเติม',
  'general.bottom_menu_home': 'หน้าหลัก',
  'general.bottom_translate': 'แปล',
  'general.bottom_menu_my_trip': 'ทริปของฉัน',
  'general.bottom_menu_booking': 'การจอง',
  'general.bottom_menu_cart': 'รถเข็น',
  'general.bottom_menu_account': 'บัญชี',

  'home.area-title': 'จุดหมายปลายทางยอดนิยมในประเทศไทย',
  'home.area-map-text': 'ค้นหาสถานที่ท่องเที่ยวชื่อดังบนแผนที่',
  'home.area-map-go': 'Go',
  'home.highlight-title': 'Highlight',
  'home.explore-title': 'สำรวจสถานที่ท่องเที่ยว',
  'home.explore-places': 'สถานที่',
  'home.activities': 'กิจกรรม',
  'home.kokoa_tab_ticket': 'บัตรผ่าน/ตั๋ว',
  'home.kokoa_tab_restaurant': 'ร้านอาหาร',
  'home.kokoa_tab_activities': 'กิจกรรม',
  'home.kokoa_tab_beauty': 'สปา/ความงาม',
  'home.kokoa_tab_tour': 'ทัวร์',
  'home.kokoa_tab_golf': 'กอล์ฟ',
  'home.best_things_to_do': 'กิจกรรมห้ามพลาด',
  'home.cultural-jurney': '#ทริปแนะนำ',
  'home.cultural-jurney-tags':
    '#ประสบการณ์หนึ่งวัน, #สัมผัสแหล่งเรียนรู้, #ดื่มด่ำวิถีชีวิต, #วัฒนธรรม, #ชาวเผ่า, #คาเฟ่, #เดินเล่น ',

  'home.intercity-transit': 'การขนส่งระหว่างเมือง',
  'home.intracity-transit': 'การขนส่งภายในเมือง',
  'home.intracity-transit-des': 'กรุณาเลือกเมือง',

  'home.transport_demestic_flight': 'สายการบิน',
  'home.transport_trains': 'รถไฟ',
  'home.transport_express_bus': 'รถบัส',
  'home.transport_mini_van': 'รถตู้',
  'home.transport_songthaew': 'รถสองแถว',
  'home.transport_taxi': 'แท็กซี่',
  'home.transport_boat_ferry': 'เรือเฟอร์รี่',
  'home.transport_mrt_bts': 'MRT/BTS',
  'home.transport_bus': 'รถบัส',
  'home.transport_map': 'แผนที่การเดินทาง',
  'home.transit_home_travel_island': 'ท่องเที่ยวบนเกาะอย่างไร?',
  'home.transit_home_travel_zone': 'เดินทางไปยังไง?',
  'home.transit_home_travel_zone_price_from': 'จาก',
  'home.ticket_home_incredible_destinations': 'จุดหมายปลายทางที่ยอดเยี่ยม',
  'home.ticket_home_explore_activities': 'สำรวจกิจกรรม',
  'home.transit_home_travel_bangkok': 'ท่องเที่ยวในกรุงเทพ',
  'home.transit_home_travel_bangkok_caption': 'โดย  MRT/BTS',
  'home.transit_home_travel_pattaya': 'ท่องเที่ยวในพัทยา',
  'home.transit_home_travel_pattaya_caption': 'โดยรถสองแถว',
  'home.transit_home_travel_chiangmai': 'ท่องเที่ยวในเชียงใหม่',
  'home.transit_home_travel_chiangmai_caption': 'โดยรถบัส/รถสองแถว',
  'home.transit_home_travel_phuket': 'ท่องเที่ยวในภูเก็ต',
  'home.transit_home_travel_phuket_caption': 'โดยรถสองแถว',
  'home.vr_home_sub_title':
    'การบริหารมรดกวัฒนธรรมสำหรับคนรุ่นต่อไปในวิวัฒนาการ',
  'home.vr_home_description1':
    'ด้วยความก้าวหน้าทางเทคโนโลยีดิจิทัล วิธีที่ผู้คนใช้ข้อมูลและข้อมูลเกี่ยวกับมรดกทางวัฒนธรรมได้เปลี่ยนแปลงไปอย่างมาก',
  'home.vr_home_description2':
    'มรดกวัฒนธรรมที่สร้างคุณค่าในอนาคตผ่านดิจิทัล',
  'home.vr_home_description3':
    'การจัดการอนุรักษ์ทางวิทยาศาสตร์และการทำงานร่วมกันอย่างต่อเนื่องในพื้นที่เสมือนกับแฝดดิจิทัล',
  'home.vr_home_explore_heritages': 'สำรวจมรดกทางวัฒนธรรมไทย แบบ 3D',
  "chance_of_rain": "โอกาสเกิดฝนตก",
  "weather_wind": "ลม",
  "weather-clear-day": "ท้องฟ้าแจ่มใส",
  "weather-clear-night": "ไม่มีเมฆ",
  "weather-cloudy": "มีเมฆมาก",
  "weather-partly-cloudy-day": "มีเมฆมากในตอนกลางวัน",
  "weather-partly-cloudy-night": "มีเมฆมากในตอนกลางคืน",
  "weather-rain": "ฝนตก",
  "weather-snow": "หิมะ",
  "weather-sleet": "ลูกเห็บตก",
  "weather-wind": "ลม",
  "weather-fog": "หมอก",
  "weather-thunderstorm": "พายุฝน",
  "menu_sightseeing": "สถานที่/แหล่งเรียนรู้",
  "menu_eating": "อาหาร",
  "menu_shopping": "แหล่งช้อปปิ้ง",
  "menu_experience": "กิจกรรมการเรียนรู้/ประสบการณ์",
  "menu_cafe_desert": "คาเฟ่/ของหวาน",
  "menu_accommodation": "ที่พัก",
  "menu_public_office": "หน่วยงานราชการ",
  "menu_information": "ข้อมูล",
  "map_link_text": "ค้นหาสถานที่ท่องเที่ยวชื่อดังบนแผนที่",
  "highlight_must_see": "#ไฮไลต์",
  "area_in": "เขต",
  "must_eat": "อาหารชื่อดังห้ามพลาด",

  "home_best_things_to_do": "กิจกรรมห้ามพลาด",
  "recommend_reading": "รีวิวสถานที่น่าสนใจ",
  "all-tab": "ทั้งหมด",
  "pull_down_refresh": "โหลดซ้ำ",
  "release_to_refresh": "เลื่อนเพื่อรีเฟรช",
  "list_see_all": "คุณดูหน้านี้หมดแล้ว",
  "shop_close": "ปิด",
  "shop_open": "เปิด",
  "reviews": "รีวิว",

  "reviewed_by": "รีวิวโดย",
  "evaluate_very_good": "ดีมาก",
  "evaluate_good": "ดี",
  "evaluate_normal": "ปานกลาง",
  "evaluate_poor": "ไม่ดี",
  "evaluate_bad": "แย่",
  "no_reviews": "ไม่มีรีวิว",
  "count_reviews": "รีวิว",
  "count_blogs": "บล็อก ",
  "call": "โทร",
  "route": "เส้นทาง",
  "bookmark": "Bookmark",
  "share": "แชร์",
  "place_tab_info": "ข้อมูล",
  "place_tab_blog": "บล็อก",
  "place_tab_review": "รีวิว",
  "weekday_mon": "วันจันทร์",
  "weekday_tue": "วันอังคาร",
  "weekday_wed": "วันพุธ",
  "weekday_thur": "วันพฤหัสบดี",
  "weekday_fri": "วันศุกร์",
  "weekday_sat": "วันเสาร์",
  "weekday_sun": "วันอาทิตย์",

  "how_to_get_there": "วิธีการเดินทาง",
  "blog_review": "บล็อกรีวิว",
  "no_blog_yet": "ยังไม่มีบล็อก",
  "rating_reviews": "รีวิวและคะแนน",
  "airplane": "สายการบิน",
  "transport_bus": "รถบัส",
  "transport_van": "รถตู้",
  "transport_train": "รถไฟ",
  "transport_ferry": "เรือเฟอร์รี่",
  "flight_departure": "สถานีต้นทาง",
  "flight_arrival": "สถานีปลายทาง",

  "onetwo_bangkok": "กรุงเทพ",
  "onetwo_ayutthaya": "อยุธยา",
  "onetwo_trat": "ตราด",
  "onetwo_ubonratchathani": "อุบลราชธานี",
  "onetwo_udonthani": "อุดรธานี",
  "onetwo_chiangrai": "เชียงราย",
  "onetwo_chiangmai": "เชียงใหม่",
  "onetwo_sukhothai": "สุโขทัย",
  "onetwo_chumphon": "ชุมพร",
  "onetwo_ranong": "ระนอง",
  "onetwo_suratthani": "สุราษฎร์ธานี",
  "onetwo_krabi": "กระบี่",
  "onetwo_nakhonsithammarat": "นครศรีธรรมราช",
  "onetwo_trang": "ตรัง",
  "onetwo_songkhla": "สงขลา",
  "onetwo_pattani": "ปัตตานี",
  "onetwo_phuket": "ภูเก็ต",
  "onetwo_kohsamui": "เกาะสมุย",
  "onetwo_huahin": "หัวหิน",
  "onetwo_pattaya": "พัทยา",
  "onetwo_booking": "การจอง",
  "onetwo_nakhonnayok": "นครนายก",
  "onetwo_samutprakan": "สมุทรปราการ",
  "onetwo_nonthaburi": "นนทบุรี",
  "onetwo_samutsakhon": "สมุทรสาคร",
  "onetwo_samutsongkhram": "สมุทรสงคราม",
  "onetwo_nakhonpathom": "นครปฐม",
  "onetwo_singburi": "สิงห์บุรี",
  "onetwo_angthong": "อ่างทอง",
  "onetwo_pathumthani": "ปทุมธานี",
  "onetwo_saraburi": "สระบุรี",
  "onetwo_chachoengsao": "ฉะเชิงเทรา",
  "onetwo_chonburi": "ชลบุรี",
  "onetwo_chanthaburi": "จันทบุรี",
  "onetwo_prachinburi": "ปราจีนบุรี",
  "onetwo_lopburi": "ลพบุรี",
  "onetwo_nakhonratchasima": "นครราชสีมา",
  "onetwo_buriram": "บุรีรัมย์",
  "onetwo_surin": "สุรินทร์",
  "onetwo_sisaket": "ศรีสะเกษ",
  "onetwo_yasothon": "ยโสธร",
  "onetwo_roiet": "ร้อยเอ็ด",
  "onetwo_mahasarakham": "มหาสารคาม",
  "onetwo_khonkaen": "ขอนแก่น",
  "onetwo_chaiyaphum": "ชัยภูมิ",
  "onetwo_nakhonphanom": "นครพนม",
  "onetwo_kalasin": "กาฬสินธุ์",
  "onetwo_mukdahan": "มุกดาหาร",
  "onetwo_sakonnakhon": "สกลนคร",
  "onetwo_nongkhai": "หนองคาย",
  "onetwo_loei": "เลย",
  "onetwo_phitsanulok": "พิษณุโลก",
  "onetwo_uttaradit": "อุตรดิตถ์",
  "onetwo_nan": "น่าน",
  "onetwo_phrae": "แพร่",
  "onetwo_phayao": "พะเยา",
  "onetwo_lampang": "ลำปาง",
  "onetwo_maehongson": "แม่ฮ่องสอน",
  "onetwo_lamphun": "ลำพูน",
  "onetwo_tak": "ตาก",
  "onetwo_kamphaengphet": "กำแพงเพชร",
  "onetwo_phichit": "พิจิตร",
  "onetwo_nakhonsawan": "นครสวรรค์",
  "onetwo_phetchabun": "เพชรบูรณ์",
  "onetwo_uthaithani": "อุทัยธานี",
  "onetwo_chainat": "ชัยนาท",
  "onetwo_kanchanaburi": "กาญจนบุรี",
  "onetwo_suphanburi": "สุพรรณบุรี",
  "onetwo_ratchaburi": "ราชบุรี",
  "onetwo_rayong": "ระยอง",
  "onetwo_phetchaburi": "เพชรบุรี",
  "onetwo_prachuapkhirikhan": "ประจวบคีรีขันธ์",
  "onetwo_phangnga": "พังงา",
  "onetwo_phatthalung": "พัทลุง",
  "onetwo_satun": "สตูล",
  "onetwo_narathiwat": "นราธิวาส",
  "onetwo_hatyai": "หาดใหญ่",
  "onetwo_kohlanta": "เกาะลันตา",
  "onetwo_kohphangan": "เกาะพะงัน",
  "onetwo_kohtao": "เกาะเต่า",
  "onetwo_kohphiphi": "เกาะพีพี",
  "onetwo_khaolak": "เขาหลัก",
  "onetwo_pranburi": "ปราณบุรี",
  "onetwo_chaam": "ชะอำ",
  "onetwo_nongbualamphu": "หนองบัวลำภู",
  "onetwo_amnatcharoen": "อำนาจเจริญ",
  "onetwo_sakaeo": "สระแก้ว",
  "onetwo_kohchang": "เกาะช้าง",
  "onetwo_yala": "ยะลา",
  "onetwo_kohlarn": "เกาะล้าน",
  "onetwo_kohlipe": "เกาะหลีเป๊ะ",
  "onetwo_donsak": "ดอนสัก",
  "onetwo_kohyaoyai": "เกาะยาวใหญ่",
  "onetwo_kohyaonoi": "เกาะยาวน้อย",
  "general.zone_all": "ทั้งหมด",
  "zone.look_around": "ดูสถานที่รอบๆ",
  "general.in_construction": "หน้าเว็บนี้ไม่สามารถรองรับฟังก์ชันนี้ได้",
  "general.service_by_app": "ดาวน์โหลดแอปพลิเคชันเพื่อรับชมอย่างเต็มรูปแบบ",
  "general.coming_soon": "Coming Soon",
  "general.open_app": "ดาวน์โหลดแอปพลิเคชัน",
  "general.see_on_map": "See on Map",
  "mrt_bts": "BTS/MRT",
  "metro_from_btn": "From",
  "metro_to_btn": "To",

};
