import React from 'react';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../redux/lang/actions';
import IntlMessages from '../../../components/common/intlMessages';
import { Dropdown } from 'react-bootstrap';
import { getLocaleName } from '../../../helper/Tools';
const tabs = [
  {
    id: 1,
    name: 'general.menu-place',
    icon: 'icomoon-favourite',
    tab: 'place',
  },
  {
    id: 2,
    name: 'general.menu-ticket',
    icon: 'icomoon-ticket',
    tab: 'ticket',
  },
  {
    id: 3,
    name: 'general.menu-transit',
    icon: 'icomoon-route',
    tab: 'transit',
  },
  {
    id: 4,
    name: 'general.menu-shopping',
    icon: 'icomoon-shopping',
    tab: 'shopping',
  },
  {
    id: 5,
    name: 'general.menu-3d',
    icon: 'icomoon-meta-3d',
    tab: '3d',
  },
];
class ProvinceHeader extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.isSticky);

    return () => {
      window.removeEventListener('scroll', this.isSticky);
    };
  }

  /* Method that will fix header after a specific scrollable */
  isSticky(e) {
    const header = document.querySelector('.home-header');
    const content = document.querySelector('.province-content');
    const scrollTop = window.scrollY;
    let height = 251;
    content.style.paddingTop = Math.max(height - scrollTop, 0) + 'px';
    if (scrollTop >= 400) {
      header.classList.add('level4');
    } else {
      header.classList.remove('level4');
    }
  }
  render() {
    const { area, locale, selectedArea } = this.props;

    return (
      <header
        className="home-header"
        style={{
          'backgroundImage':
            'linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 184, 0, 0.5)), url("' +
            selectedArea?.img_link +
            '")',
        }}
      >
        <div className="content-box">
          <h1 className="header-title">treasure</h1>
          <div className="header-tabs">
            {tabs.map((e, index) => (
              <div
                className={'header-tab-item ' + (e.id === 1 ? 'active' : '')}
                key={`home-place-key-${index}`}
                onClick={() => {
                  if (e.id === 1) {
                    window.location.replace('/');
                  } else if (e.id === 3) {
                    window.location.replace('/#transit');
                  } else if (e.id === 5) {
                    window.location.replace('/#vr');
                  } else {
                    window.location.replace('/comingSoon');
                  }

                }}
              >
                <i className={e.icon}></i>

                <p className="tab-item-title">
                  <IntlMessages id={e.name} />
                </p>
              </div>
            ))}
          </div>
          <div className="tab-content province-content" style={{ 'paddingTop': '251px' }}>
            <div className="province-switch-box">
              <Dropdown>
                <Dropdown.Toggle variant="area-selector" id="dropdown-basic">
                  {selectedArea === null ? (
                    <IntlMessages id="general.area-thailand" />
                  ) : (
                    getLocaleName(selectedArea, locale)
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {area.map((e, i) => (
                    <Dropdown.Item
                      key={'header-area-' + i}
                      href="#"
                      onClick={() => {
                        window.location.replace(`/province/${e.id}`);
                        // setHomeState(e);
                      }}
                    >
                      {getLocaleName(e, locale)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.language;
  const { area } = state.area;
  return { locale, area };
};

const mapDispatchToProps = (dispatch) => ({
  changLang: (val) => {
    dispatch(changeLanguage(val));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProvinceHeader);
