import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './redux/lang/reducer';
import areaReducer from './redux/area/reducer';

const store = configureStore({
  reducer: {
    // Define a top-level state field named `todos`, handled by `todosReducer`
    language: languageReducer,
    area: areaReducer,
  },
});

export default store;
