import { useNavigate, useParams } from 'react-router-dom';
import { noImage400 } from './defaultValues';

export const getLocaleName = (area, locale) => {
  if (area === null) {
    return '';
  }
  switch (locale) {
    case 'en':
      return area.name_en;
    case 'th':
      return area.name_th;
    default:
      return area.name_en;
  }
};
export const getLocaleDetail = (area, locale) => {
  if (area === null) {
    return '';
  }

  switch (locale) {
    case 'en':
      return area.detail_en;
    case 'th':
      return area.detail_th;
    default:
      return area.detail_en;
  }
};
export const getLocaleDescription = (area, locale) => {
  if (area === null) {
    return '';
  }

  switch (locale) {
    case 'en':
      return area.description_en;
    case 'th':
      return area.description_th;
    default:
      return area.description_en;
  }
};
export const getLocaleTitle = (area, locale) => {
  if (area === null) {
    return '';
  }
  switch (locale) {
    case 'en':
      return area.title_en;
    case 'th':
      return area.title_th;
    default:
      return area.title_en;
  }
};

export const splitArrayByNo = (list, no) => {
  return [].concat.apply(
    [],
    list.map(function (elem, i) {
      return i % no ? [] : [list.slice(i, i + no)];
    })
  );
};
export const currencyFormat = (num, ind = 2) => {
  return num.toFixed(ind).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
export const withNavigateHook = (Component) => {
  return (props) => {
    const navigation = useNavigate();
    const params = useParams();

    return <Component navigation={navigation} params={params} {...props} />;
  };
};

export const getOpenCloseTime = (current, openHours) => {
  let openhour;
  let closehour;
  switch (current.getDay()) {
    case 1:
      openhour = Number(openHours.mon.open
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));

      closehour = Number(openHours.mon.close
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));
      break;
    case 2:
      openhour = Number(openHours.tue.open
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));

      closehour = Number(openHours.tue.close
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));
      break;

    case 3:
      openhour = Number(openHours.wed.open
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));

      closehour = Number(openHours.wed.close
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));
      break;
    case 4:
      openhour = Number(openHours.thur.open
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));

      closehour = Number(openHours.thur.close
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));
      break;
    case 5:
      openhour = Number(openHours.fri.open
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));

      closehour = Number(openHours.fri.close
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));
      break;
    case 6:
      openhour = Number(openHours.sat.open
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));

      closehour = Number(openHours.sat.close
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));
      break;
    case 0:
      openhour = Number(openHours.sun.open
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));

      closehour = Number(openHours.sun.close
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));
      break;
    default:
      openhour = Number(openHours.mon.open
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));

      closehour = Number(openHours.mon.close
        .replace(":", "")
        .replace(".", "")
        .replace(",", ""));
      break;
  }
  return { 'open': openhour, 'close': closehour };
}
export const getGetParams = () => {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}
export const getZoneImage = (area, zone) => {
  if (area === null) {
    return noImage400;
  }
  if (zone === 'sandbox') {
    zone = 'sendbox'
  }
  if (area.image_zone[zone] !== null && area.image_zone[zone] !== '') {
    return area.image_zone[zone];
  }
  return area.image;
}
export const dateString = (time, format) => {
  var dateFormat = new Date(time);
  return format
    .replace("Y", dateFormat.getFullYear())
    .replace("m", ("0" + (dateFormat.getMonth() + 1)).slice(-2))
    .replace("d", ("0" + dateFormat.getDate()).slice(-2))
    .replace("h", dateFormat.getHours())
    .replace("i", dateFormat.getMinutes());
}

export const toCurrecy = (amount, num) => {
  return amount
    .toFixed(num)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const centerPoint = (coordinates) => {
  let x = coordinates.map(c => c.latitude)
  let y = coordinates.map(c => c.longitude)

  let minX = Math.min.apply(null, x)
  let maxX = Math.max.apply(null, x)

  let minY = Math.min.apply(null, y)
  let maxY = Math.max.apply(null, y)

  return {
    latitude: (minX + maxX) / 2,
    longitude: (minY + maxY) / 2
  }
}