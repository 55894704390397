import React from "react";
import { ComingSoon } from "../../ComingSoon";


export class HomeComing extends React.Component {
    render() {
        return (
            <div className="home-page page-clear">
                <ComingSoon />
            </div>
        );
    }
}