import axios from "axios";

export const get12GoTrip = async (from, to, date, locale) => {
    const url = `https://recheck10.12go.co/api/v1/search/${from}/${to}/${date}`;
    const params = {
        "lang": locale,
        "with_price": "3",
        "seats": "1",
        "ref": "https%3A%2F%2Ftreasure-thailand.12go.asia",
        "a": "4126052",
        "ajax": "1",
    }

    try {
        let res = await axios
            .post('/api/v1/getWebDataByURL', {
                'url': url,
                'params': JSON.stringify(params),
                'method': 'get'
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            },)
        if (res.status === 200) {
            // test for status you want, etc
            console.log(res.status)
        }
        // Don't forget to return something   
        return res.data
    }
    catch (err) {
        console.error(err);
    }

}
export const getLocal12GoTrip = async (from, to, date, locale) => {
    let res = await fetch('/assets/json/trip.json'
        , {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    )
    return res.json();

}
export const getLocal12GoExchange = async () => {
    let res = await fetch('/assets/json/price.json'
        , {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    )
    return res.json();
}
export const get12GoExchange = async () => {
    try {
        let res = await axios
            .get('/api/v1/get12GoPrice', {
                headers: {
                    'Content-Type': 'application/json'
                }
            },)
        if (res.status === 200) {
            // test for status you want, etc
            console.log(res.status)
        }
        // Don't forget to return something   
        return res.data
    }
    catch (err) {
        console.error(err);
    }
}
