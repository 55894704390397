import { Datepicker } from "@meinefinsternis/react-horizontal-date-picker";
import React from "react";
import { connect } from "react-redux";
import { get12GoExchange, get12GoTrip } from "../../helper/12go";
import { enUS, th } from "date-fns/locale";
import TranslateHeader from "../../components/common/TranslateHeader";
import IntlMessages from "../../components/common/intlMessages";
import { Dropdown } from "react-bootstrap";
import { dateString, getGetParams, toCurrecy } from "../../helper/Tools";
import { get12GoDestination } from "../../helper/FirestoreApi";
import Loading from "../../components/common/Loading";
import Footer from "../../components/common/Footer";

const bangkokSlug = {
    id: '1p',
    name: 'onetwo_bangkok',
    slug: 'bangkok',
    airport: true,
    bus: true,
    ferry: false,
    van: true,
    relatedFerry: []
};
const phuketSlug = {
    id: '73p',
    name: 'onetwo_phuket',
    slug: 'phuket',
    airport: true,
    bus: true,
    ferry: true,
    van: true,
    relatedFerry: ["75p", "78p", "1124p"],
};

class Transport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startValue: new Date(),
            endValue: new Date(),
            departure: null,
            arrival: null,
            regions: [],
            exchange: [],
            trips: null,
            type: 'bus',
        };
    }
    async componentDidMount() {

        let exchange = await get12GoExchange();
        var parts = getGetParams();
        let departure = bangkokSlug;
        let arrival = phuketSlug;
        let type = "bus";
        if (parts.type) {
            type = parts.type;
        }
        let regions = await get12GoDestination(type);
        regions.sort((a, b) => a.name.localeCompare(b.name));
        let color = '#35BE82';
        switch (type) {
            case 'bus':
                arrival = regions.find((e) => e.id === '44p');
                break;
            case 'train':
                arrival = regions.find((e) => e.id === '44p');
                break;
            case 'ferry':
                departure = regions.find((e) => e.id === '73p');
                arrival = regions.find((e) => e.id === '78p');
                break;
            default:
                break;
        }
        if (parts.departure) {
            let pub = parts.departure;
            if (pub === 'songkhla') {
                pub = "hat-yai";
            } else if (pub === 'chiangmai') {
                pub = "chiang-mai";
            } else if (pub === 'huahin') {
                pub = "hua-hin";
            }
            departure = regions.find((e) => e.slug === pub);
            if (arrival.id === departure.id) {
                arrival = bangkokSlug;
            }
        }
        if (parts.arrival) {
            let pub = parts.arrival;
            if (pub === 'songkhla') {
                pub = "hat-yai";
            } else if (pub === 'chiangmai') {
                pub = "chiang-mai";
            } else if (pub === 'huahin') {
                pub = "hua-hin";
            }
            arrival = regions.find((e) => e.slug === pub);
        }

        this.setState({
            regions: regions,
            exchange: exchange,
            departure: departure,
            arrival: arrival,
            type: type,
            color: color,
        }, () => {
            this.getTrips();
        });
    }
    async getTrips() {
        const { locale } = this.props;
        this.setState({
            trips: null,
        });
        let date = dateString(this.state.startValue, "Y-m-d");
        let result = await get12GoTrip(this.state.departure.id, this.state.arrival.id, date, locale);
        let resultTrips = result.trips.filter(e => e.params.vehclasses.includes(this.state.type));
        let trips = [];
        if (resultTrips.length > 0) {
            for (const d of resultTrips) {
                let fxcode = d.params.min_price.fxcode;
                let baht = Math.round(d.params.min_price.value);
                if (fxcode !== 'THB') {
                    var dollar = d.params.min_price.value / this.state.exchange[fxcode][0];
                    baht = Math.round(dollar * this.state.exchange['THB'][0]);
                } else {
                }
                let newItem = {
                    'id': d.id !== undefined ? d.id : "",
                    'route_name': d.route_name,
                    'dep_time': d.params.dep_time,
                    'arr_time': d.params.arr_time,
                    'flight_code': d.segments[0] !== undefined ? d.segments[0].official_id : "",
                    'price': baht,
                    'duration': d.params.duration ?? 0,
                    'confirmation_message': d['travel_options'][0]['confirmation_message'] ?? "",
                    'cancellation_message': d['travel_options'][0]['cancellation_message'] ?? "",
                    'booking_url': d['travel_options'][0]['booking_uri'] ?? "",
                    'from': result['stations'][d.params.from],
                    'to': result['stations'][d.params.to],
                    'operator': result['operators'][d.params.operators[0]],
                    'bookable': d.params.bookable,
                    'reason': d.params.reason ?? "",
                };
                trips.push(newItem);
            }
        }
        trips.sort((a, b) => a.price - b.price);
        this.setState({
            trips: trips,
        });

    }


    handleChange(d) {
        const [startValue,] = d;
        this.setState({
            startValue: startValue,
            endValue: startValue,
        }, () => {
            this.getTrips();
        })
    };
    durationText(duration) {
        duration = Number(duration);
        let durationText = "";
        if (duration > 1440) {
            let day = Math.floor(duration / 1440);
            duration -= day * 1440;
            durationText += day + "d ";
        }
        if (duration > 60) {
            let hours = Math.floor(duration / 60);
            duration -= hours * 60;
            durationText += hours + "h ";
        }
        if (duration > 0) {
            durationText += duration + "m";
        }

        return durationText;
    }

    render() {
        if (this.state.departure === null && this.state.arrival === null) {
            return <Loading />
        }
        const { locale } = this.props;
        return (<div className={"default-content transport-content transport-" + this.state.type}>
            <TranslateHeader isBack={true} title={"transport_" + this.state.type} />
            <div className="header-direction" >
                <div className="direction-from">
                    <p className="direction-name"><IntlMessages id="flight_departure" /></p>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="region-selector"
                            id="dropdown-departure"
                        >
                            {this.state.departure === null ? (
                                <IntlMessages id="general.area-thailand" />
                            ) : (
                                <IntlMessages id={this.state.departure.name} />
                            )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="region-menu">

                            {this.state.regions.length > 0 && this.state.regions.map((e, i) => (
                                <Dropdown.Item
                                    key={'header-area-' + i}
                                    href="#"
                                    onClick={() => {
                                        this.setState({
                                            departure: e
                                        }, () => {
                                            this.getTrips();
                                        });
                                    }}
                                >
                                    <IntlMessages id={e.name} />
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="direction-arrow">
                    <i className="icomoon-angle-double-right"></i>
                </div>
                <div className="direction-to">
                    <p className="direction-name"><IntlMessages id="flight_arrival" /></p>
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="region-selector"
                            id="dropdown-arrival"
                        >
                            {this.state.arrival === null ? (
                                <IntlMessages id="general.area-thailand" />
                            ) : (
                                <IntlMessages id={this.state.arrival.name} />
                            )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="region-menu">

                            {this.state.regions.length > 0 && this.state.regions.map((e, i) => (
                                <Dropdown.Item
                                    key={'header-area-' + i}
                                    href="#"
                                    onClick={() => {
                                        this.setState({
                                            arrival: e
                                        }, () => {
                                            this.getTrips();
                                        });
                                    }}
                                >
                                    <IntlMessages id={e.name} />
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="content-box transport-date">
                <Datepicker
                    classNames={{
                        "selectedDay": 'transport-selected',
                    }}
                    onChange={(e) => this.handleChange(e)}
                    locale={locale === 'en' ? enUS : th}
                    startValue={this.state.startValue}
                    endValue={this.state.endValue}
                />
            </div>
            <div className="content-box trips-content-box">
                {this.state.trips ?
                    <div className="trips-list">
                        {this.state.trips.map((e, i) => {

                            let image = e.operator.logo !== null
                                ? "https://img.12go.co/0/fit/150/150/ce/0/plain/s3://12go-web-static/static/" + e.operator.logo[0]
                                : ""
                            return (<div className="trip-item" key={`trips-item-${i}`}>
                                <div className="trip-header" >
                                    <div className="header-departure">
                                        <p className="code">{e.from.station_code}</p>
                                        <p className="name">{e.from.station_name}</p>
                                        <p className="time">{e.dep_time.substring(11, 16)}</p>
                                    </div>
                                    <div className="header-connect">
                                        <p className="duration">{this.durationText(e.duration)}</p>
                                        {(() => {
                                            switch (this.state.type) {
                                                case 'bus':
                                                    return <img src="/assets/icons/con_express_bus.png" alt="connect-img" />;
                                                case 'van':
                                                    return <img src="/assets/icons/con_mini_van.png" alt="connect-img" />;
                                                case 'ferry':
                                                    return <img src="/assets/icons/con_ferry.png" alt="connect-img" />;
                                                default:
                                                    return null;
                                            }
                                        })()}
                                    </div>
                                    <div className="header-arrival">
                                        <p className="code">{e.to.station_code}</p>
                                        <p className="name">{e.to.station_name}</p>
                                        <p className="time">{e.arr_time.substring(11, 16)}</p>
                                    </div>
                                </div>
                                <div className="ticket-info">
                                    <div className="operator">
                                        {image !== '' && <img src={image} alt="operator-logo" />}
                                        <span className="operator-name">{e.operator.name}</span>
                                        <span className="operator-code">{e.flight_code}</span>
                                    </div>
                                    {e.booking_url !== '' ?
                                        <div className="ticket-price" onClick={() => {
                                            window.location.href = "https://treasure-thailand.12go.asia" + e.booking_url
                                        }}>{`฿ ${toCurrecy(e.price, 0)}`}</div>
                                        : <div className="ticket-price disabled"  >{`฿ ${toCurrecy(e.price, 0)}`}</div>
                                    }
                                </div>
                            </div>)
                        }
                        )}
                    </div>
                    : <div className='text-center py-5'><div className='spinner'><div></div><div></div><div></div></div></div>
                }
            </div>
            <Footer selectedIndex="1"></Footer>
        </div>)
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Transport);
