import {
  getAreaList,
  getAreaUpdated,
  setAreaList,
  setAreaUpdated,
} from '../../helper/localStorage';
import { UPDATE_AREA } from './constants';

const initialState = {
  area: getAreaList(),
  updated: getAreaUpdated(),
};

export default function languageReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AREA:
      setAreaList(action.area);
      setAreaUpdated(action.area);
      return {
        locale: action.area,
      };
    default:
      return state;
  }
}
