import React from 'react';
import { connect } from 'react-redux';
import HomeHeader from './widget/HomeHeader';
import HomePlace from './widget/HomePlace';
import Footer from '../../components/common/Footer';
import HomeTransit from './widget/HomeTransit';
import '../../css/home.scss';
import HomeVR from './widget/HomeVR';
import { HomeComing } from './widget/HomeComing';

class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedArea: null,
            areas: [],
            selectedTab: 1,
        };
    }
    async componentDidMount() {
        let { area } = this.props;
        if (window.location.hash === '#transit') {
            this.setState({
                selectedTab: 3,
            });
        } else if (window.location.hash === '#vr') {
            this.setState({
                selectedTab: 5,
            });

        }
        this.setState({
            areas: area,
        });
    }

    changeArea(area) {
        this.setState({
            selectedArea: area,
        });
    }
    render() {
        return (
            <div className="home-content" style={{ "padding": '15px' }}>
                <h2 >
                    Privacy and Cookie Statement
                </h2>
                <h5 >Effective:
                    1 May 2020</h5>
                <p >
                    We know that sharing your personal information
                    with us is based on trust. We take this
                    seriously and are committed to ensuring that we
                    respect your privacy when you visit our website
                    or use our applications. Please review this
                    Statement carefully to learn
                    about our privacy practices.
                </p>
                <p >We operate
                    an online service that provides users with
                    recommendations for travel and travel planning,
                    including hotels, holiday rentals and other
                    accommodations, attractions and experiences,
                    restaurants, travel guides and lots more. In
                    this Statement, these are collectively referred
                    to as our Services.</p>
                <p >Our
                    Services are owned and operated by Nex To Be
                    Compnay Limited. By visiting our websites and
                    related applications, whether on a computer,
                    phone, tablet, or similar device (these are
                    all referred to as a “Device”), you are
                    accepting the practices described below.</p>
                <h5 >
                    Information Collected</h5>
                <p >When you
                    access or use our Services, we collect
                    information from and about you to provide a more
                    personalized and relevant experience. Some
                    information we collect automatically. Other
                    information we collect from different sources,
                    including affiliated entities, business
                    partners, and other independent third-party
                    sources. When you use our Services by
                    “clicking-through”
                    from a third-party site or when you visit
                    third-party sites via our Services, those
                    third-party sites may share information with us
                    about your use of their service. Information
                    collected
                    may include the following:</p>
                <ul >
                    <li>Contact information, including name, phone
                        number and postal and email addresses</li>
                    <li>Billing or payment information (such as your
                        credit card number, cardholder name,
                        expiration date, authentication code, and
                        billing address) </li>
                    <li>User name and password</li>
                    <li>Photos, reviews, forum and social posts, and
                        videos you may have provided to us</li>
                    <li>Geolocation information</li>
                    <li>Device information, such as when you
                        accessed our Services and information about
                        the Device used (for example, IP address,
                        software or internet browser used, preferred
                        languages,
                        unique Device identifiers and advertising
                        identifiers) </li>
                    <li>Online activity, including pages you have
                        visited, content reviewed, and apps reviewed
                    </li>
                    <li>Purchasing history</li>
                    <li>Information about your travel plans and
                        preferences</li>
                </ul>
                <p >We may also
                    collect, in instances where you have provided
                    it, information about other travellers,
                    including their email address and other
                    travel-related information. If you are sharing
                    information with us about other individuals, you
                    must obtain their consent. You must obtain the
                    consent of any other individuals if you are
                    providing their data to us.</p>
                <p >In addition
                    to the categories noted above, we may also
                    collect location information if you have
                    instructed your Device to send such information
                    via the privacy settings on that Device,
                    or, for example, if you have uploaded photos
                    tagged with location information. We may use
                    your location information directly and/or share
                    your location with third-parties. We collect
                    and share location information in order to
                    provide you with relevant content, to analyze
                    your use of our Services, to improve our
                    Services and to provide contextual advertising
                    or
                    recommendations. For example, we may use your
                    location to show you reviews of hotels,
                    restaurants or attractions near you when you are
                    traveling.</p>
                <p >You can
                    change the privacy settings of your Device at
                    any time in order to turn off the functionality
                    that collects and shares location information
                    and/or the functionality to tag your
                    photos with location information. However,
                    turning off location-sharing may affect certain
                    features we offer. If you have any questions
                    about the privacy settings of your Device,
                    we suggest you contact the manufacturer of your
                    Device or your mobile service provider for help.
                </p>
                <p >Please see
                    the section on Cookies below for more
                    information regarding the use of cookies and
                    other technology described in this section,
                    including regarding your choices relating to
                    such technologies.</p>
                <h5 >
                    Information Uses</h5>

                <p >We are
                    committed to providing you with relevant content
                    on our Services and respect the data privacy
                    laws of the different jurisdictions which we
                    operate in. We use information about
                    you so we can help you enjoy and use our
                    Services, including in the following ways:</p>
                <ul >
                    <li>Register and manage your account, including
                        to allow you access to and use of our
                        Services
                    </li>
                    <li>Facilitate your bookings
                    </li>
                    <li>Measure interest in and improve our Services
                    </li>
                    <li>Notify you about special offers and products
                        or services available from us, our
                        affiliates, or our partners that may be of
                        interest to you
                    </li>
                    <li>Communicate with you
                    </li>
                    <li>Enable communication between registered
                        users and owners/representatives of listings
                        on our Services
                    </li>
                    <li>Enable us to publish your reviews, forum
                        posts, photos, videos and other content
                    </li>
                    <li>Customize your experience, including
                        customizing the ads shown to you on our
                        Services and across the internet
                    </li>
                    <li>Provide you with an optimized experience and
                        to group users of our Services based on, for
                        example, usage and demographics
                    </li>
                    <li>Send you survey or market research
                        invitations
                    </li>
                    <li>Respond to your questions and comments
                    </li>
                    <li>Resolve disputes or troubleshoot problems
                    </li>
                    <li>Prevent potentially prohibited or unlawful
                        activities
                    </li>
                    <li>Enforce our terms of use and notices; and as
                        otherwise described to you at the point of
                        collection
                    </li>
                </ul>
                <p >Please note
                    some information about you is required in order
                    for us to provide you with relevant offerings
                    from us, our affiliates, and our partners and to
                    enable you to enjoy other benefits
                    of being a registered user. Please review the
                    “Information Choices” section below with respect
                    to your choices related to the collection and
                    use of your information.</p>
                <h5 >
                    Information Sharing</h5>
                <p >In order to
                    provide some of our Services, we may need to
                    share information with certain other
                    third-parties, including our group of companies,
                    in the following circumstances:</p>
                <ul >
                    <li>Suppliers. We share information with certain
                        suppliers used to facilitate our Services,
                        including to help with transactions, such as
                        any reservations you might make, for
                        example,
                        sharing data with a restaurant if you make a
                        reservation using our Services. </li>
                    <li>Business Partners. We may share information
                        about you, your Devices and your use of our
                        Services with our trusted business partners.
                        For example, we may share information so
                        that
                        we can provide you with relevant content or
                        in order to facilitate payment for products
                        or services you may purchase on the websites
                        of our partners. This sharing is generally
                        pursuant to written agreements which include
                        confidentiality, privacy and security
                        obligations; however, note that we do not
                        control the privacy practices of these
                        third-party
                        business partners.
                    </li>
                    <li>Social Media Sites. When you use our
                        Services and elect to share information with
                        third-party social media sites, the
                        information you share will be governed by
                        the privacy policies
                        of those social media sites and the privacy
                        settings you have set with those social
                        media websites.
                    </li>
                    <li>Social Media Sites. When you use our
                        Services and elect to share information with
                        third-party social media sites, the
                        information you share will be governed by
                        the privacy policies
                        of those social media sites and the privacy
                        settings you have set with those social
                        media websites.
                    </li>
                    <li>Advertising Networks. We sometimes collect
                        and share information about your interests
                        with advertising networks. Some of these
                        companies are members of the Network
                        Advertising Initiative,
                        or the Digital Advertising Alliance, which
                        offer a single location to opt-out of ad
                        targeting from member companies.
                    </li>
                    <li>Other third-parties, such as referring
                        websites. Third-parties may also assist us
                        in collecting information by, for example,
                        operating features of our website or
                        facilitating the
                        delivery of online advertising tailored to
                        your interests. We may share audience
                        segments and other information with
                        third-parties that use that information for
                        tailored advertising
                        to you.
                    </li>
                </ul>
                <p >
                    Third-parties may only collect or access
                    information as needed to perform their permitted
                    functions.</p>
                <p >Certain
                    Device operating system versions permit you to
                    opt out of certain types of information sharing,
                    including to certain advertising networks.
                    Please check your Device settings if
                    you want to limit such tracking.</p>
                <h5 >
                    Information Security</h5>
                <p >We have
                    implemented appropriate administrative,
                    technical, and physical security procedures to
                    help protect your information.</p>
                <p >We only
                    authorize specific employees to access personal
                    information and they may do so only for
                    permitted business functions. We use encryption
                    when transmitting your information between
                    your system and ours, and between our system and
                    those of the parties with whom we share
                    information. We also employ firewalls and
                    intrusion detection systems to help prevent
                    unauthorized
                    access to your information. However, we cannot
                    guarantee the security of information from
                    unauthorized entry or use, hardware or software
                    failure, or other circumstances outside of
                    our control.</p>
                <h5 >Cookies
                </h5>

                <p >We want
                    your access to our Services to be as easy,
                    efficient and useful as possible. To help us do
                    this, we use cookies and similar technologies to
                    improve your experience, to enhance
                    site security and to show you relevant
                    advertising.
                </p>
                <p >What are
                    cookies?
                </p>
                <p >Cookies are
                    small text files that are automatically placed
                    on your Device when you visit almost any
                    website. They are stored by your internet
                    browser and contain basic information about
                    your internet use. Your browser sends these
                    cookies back to a website every time you revisit
                    it, so it can recognize your Device and improve
                    your experience by, among other things,
                    providing you with personalized content. We also
                    use cookies to remember your login details, so
                    you don’t have to re-enter them repeatedly when
                    you use our Services. Other cookies
                    help us understand your preferences.
                </p>
                <p >The cookies
                    we use fall into three categories.
                </p>
                <ul >
                    <li>Essential: These cookies are necessary to
                        help you access and move around the website
                        and use all its features. Without these
                        cookies, the website would not work properly
                        and you
                        would not be able to use certain important
                        features.</li>
                    <li>Analytics and customisation: We use these
                        cookies to help us understand how the
                        website is being used and how we can improve
                        your experience on it. These cookies can
                        provide us with
                        information to help us understand which
                        parts of the website interest our visitors
                        and if they experience any errors. We use
                        these cookies to test different designs and
                        features
                        for our sites and we also use them to help
                        us monitor how visitors reach the website.
                    </li>
                    <li>Advertising: Advertising cookies help ensure
                        that the advertisements you see are as
                        relevant to you as possible. For example,
                        some advertising cookies help select ads
                        that are based
                        on your interests. Others help prevent the
                        same ad from continuously reappearing for
                        you.
                    </li>
                    <li>We also use cookies to make it easy to share
                        content from the website with your friends
                        through your favorite social networks.
                        Social media sites, may set cookies that
                        recognize you
                        when you view content on the website and
                        allow you to share content across both the
                        website and the social media site via the
                        use of sharing settings. For further
                        details, please
                        check your social media site’s terms of use
                        and privacy policy.
                    </li>
                </ul>
                <h5 >Statement
                    Changes and Notification</h5>
                <p >We may
                    update this Statement in the future. If we
                    believe any changes are material, we will let
                    you know by doing one or more of the following:
                    sending you a communication about the changes,
                    placing a notice on the website and/or posting
                    an updated Statement on the website. We will
                    note at the top of this Statement when it was
                    most recently updated. We encourage you to
                    check back from time to time to review the most
                    current version and to periodically review this
                    Statement for the latest information on our
                    privacy practices
                </p>
                <h5 >Contact
                </h5>
                <p >If you have
                    a data privacy request, such as a request to
                    delete or access your data, please send email to
                    info@thaitreasuretrip.com For general data privacy
                    inquiries or questions concerning
                    our Privacy and Cookies Statement, please
                    contact our privacy team by sending email to
                    info@thaitreasuretrip.com
                </p>
                <Footer selectedIndex="1"></Footer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    const { area } = state.area;
    return { locale, area };
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Privacy);
