import React from 'react';
import { connect } from 'react-redux';
import HomeHeader from './widget/HomeHeader';
import HomePlace from './widget/HomePlace';
import Footer from '../../components/common/Footer';
import HomeTransit from './widget/HomeTransit';
import '../../css/home.scss';
import HomeVR from './widget/HomeVR';
import { HomeComing } from './widget/HomeComing';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedArea: null,
      areas: [],
      selectedTab: 1,
    };
  }
  async componentDidMount() {
    let { area } = this.props;
    if (window.location.hash === '#transit') {
      this.setState({
        selectedTab: 3,
      });
    } else if (window.location.hash === '#vr') {
      this.setState({
        selectedTab: 5,
      });

    }
    this.setState({
      areas: area,
    });
  }

  changeArea(area) {
    this.setState({
      selectedArea: area,
    });
  }
  render() {
    return (
      <div className="home-content">
        <HomeHeader
          areas={this.state.areas}
          selectedTab={this.state.selectedTab}
          setSelectTabState={(id) => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            this.setState({
              selectedTab: id,
            })
          }
          }
          selectedArea={this.state.selectedArea}
          setHomeState={(area) => this.changeArea(area)}
        />
        {(() => {
          switch (this.state.selectedTab) {
            case 1:
              return <HomePlace areas={this.state.areas}></HomePlace>;
            case 3:
              return <HomeTransit areas={this.state.areas}></HomeTransit>;
            case 5:
              return <HomeVR areas={this.state.areas}></HomeVR>;
            default:
              return <HomeComing />;
          }
        })()}
        <Footer selectedIndex="1"></Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.language;
  const { area } = state.area;
  return { locale, area };
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Home);
