import React from "react";
import Footer from "../components/common/Footer";
import TranslateHeader from "../components/common/TranslateHeader";
import { ComingSoon } from "./ComingSoon";

export class ComingSoonPage extends React.Component {
    render() {
        return (

            <div className="default-content coming-soon-content">
                <TranslateHeader isBack={true} title="general.coming_soon" />
                <ComingSoon />
                <Footer selectedIndex="1"></Footer>
            </div>
        )
    }
}