import React from 'react';
import { connect } from 'react-redux';
import { changeLanguage } from '../../../redux/lang/actions';
import IntlMessages from '../../../components/common/intlMessages';
import { firestore } from '../../../helper/Firebase';
import { getLocaleName } from '../../../helper/Tools';

class HomeVR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vr: [],
    };
  }
  async componentDidMount() {
    let vrplaces = await this.getVRPlace();
    this.setState({
      vr: vrplaces,
    });
  }
  async getVRPlace() {
    try {
      // console.log(id,state);
      let value = await firestore
        .collection(`data/vr/places`)
        .orderBy('position', 'asc')
        .get();
      return value.docs.map((g) => g.data());
    } catch (e) {
      console.log(e);
      return [];
    }
  }
  render() {
    const { locale } = this.props;
    return (
      <div className="home-page page-clear">
        <div className="vr-header-empty"></div>
        <div className="content-box">
          <p className="vr-title">
            <IntlMessages id="home.vr_home_sub_title" />
          </p>
          <p className="vr-description">
            <IntlMessages id="home.vr_home_description1" />
          </p>
          <p className="vr-description">
            <IntlMessages id="home.vr_home_description2" />
          </p>
          <p className="vr-description">
            <IntlMessages id="home.vr_home_description3" />
          </p>
        </div>
        <div className="content-box">
          <div className="content-box-header">
            <h4 className="page-title">
              <IntlMessages id="home.vr_home_explore_heritages"></IntlMessages>
            </h4>
          </div>
          <div className="vr-place-list">
            {this.state.vr.map((e, i) => (
              <div
                className="vr-place-item"
                key={`vr-place-${i}`}
                onClick={() => {
                  window.open(e.value, '_blank');
                }}
              >
                <img src={e.img_link} alt="vr-place" />
                {getLocaleName(e, locale) !== '' && (
                  <p className="vr-place-name">{getLocaleName(e, locale)}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.language;
  return { locale };
};

const mapDispatchToProps = (dispatch) => ({
  changLang: (val) => {
    dispatch(changeLanguage(val));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeVR);
