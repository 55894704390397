import React from "react";
import IntlMessages from "../components/common/intlMessages";

export class ComingSoon extends React.Component {
    render() {
        return (<div className="content-box  coming-soon-page">
            <img src="/assets/img/app-main.png" alt="coming-soon-img" />
            <h3><IntlMessages id="general.in_construction" /></h3>
            <p>
                <IntlMessages id="general.service_by_app" />
            </p>
            <a href="https://treasurethailand.page.link/bjYi" className="btn btn-sm btn-primary px-5"><IntlMessages id="general.open_app" /></a>
        </div>)
    }
}