import React from 'react';
import { connect } from 'react-redux';
import { getGetParams, getLocaleName, withNavigateHook } from '../../helper/Tools';
import InfiniteScroll from 'react-infinite-scroll-component';
import IntlMessages from '../../components/common/intlMessages';
import Footer from '../../components/common/Footer';
import { algoliaSearch } from '../../helper/algolia';
import { noImage800 } from '../../helper/defaultValues';



class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            page: 1,
            isEnd: false,
            isLoad: false,
            keyword: ''
        };
    }
    async componentDidMount() {

        var parts = getGetParams();
        let keyword = "";
        if (parts.keyword !== undefined) {
            this.setState({
                'keyword': parts.keyword,
            });
            keyword = parts.keyword;
            document.getElementById('search').value = parts.keyword;
        }
        this.refresh(keyword)

    }
    fetchData(keyword) {
        if (this.state.isLoad) {
            return;
        }
        let filterText = `active:true`;
        this.setState({
            isLoad: true
        })
        algoliaSearch.search(keyword, {
            filters: filterText,
            hitsPerPage: 18,
            page: this.state.page,
        }).then(({ hits, nbHits }) => {
            if (hits !== undefined) {

                this.setState({
                    isLoad: false,
                    page: this.state.page + 1,
                    isEnd: hits.length < 18,
                    items: this.state.items.concat(hits)
                });
            } else {
                this.setState({
                    isLoad: false
                });
            }
        });

    }
    refresh(keyword) {
        if (this.state.isLoad) {
            return;
        }
        this.setState({
            isLoad: true
        })

        let filterText = `active:true`;

        algoliaSearch.search(keyword, {
            filters: filterText,
            hitsPerPage: 18,
            page: 0,
        }).then(({ hits, nbHits }) => {
            if (hits !== undefined) {

                this.setState({
                    isLoad: false,
                    page: 1,
                    isEnd: hits.length < 18,
                    items: hits
                });
            } else {
                this.setState({
                    isLoad: false
                });
            }
        });
    }


    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.refresh(e.target.value)
            this.setState({
                'keyword': e.target.value
            });
        }
    }

    render() {
        let { locale } = this.props;

        return (
            <div className="default-content">
                <header className="header">
                    <div className="content-box">
                        <div className='header-box'>
                            <div className='header-icon'>
                                <span onClick={() => window.history.back()}><i className='icomoon-arrow-left'></i></span>
                            </div>
                            <div className='header-search'>
                                <div className="search-bar">
                                    <b className="search-icon"><i className="icomoon-search"></i></b>
                                    <hr />
                                    <input className="" type="search" name="search" id="search" placeholder="" onKeyDown={this._handleKeyDown} />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className='content-box'>
                    <InfiniteScroll
                        className='place-list'
                        dataLength={this.state.items.length} //This is important field to render the next data
                        next={() => this.fetchData(this.state.tag?.id, this.state.zone)}
                        hasMore={!this.state.isEnd}
                        loader={<div className='text-center'><div className='spinner'><div></div><div></div><div></div></div></div>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b> <IntlMessages id="list_see_all" /></b>
                            </p>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={() => this.refresh(this.state.tag?.id, this.state.zone)}
                        pullDownToRefresh
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>
                                &#8595; <IntlMessages id="pull_down_refresh" />
                            </h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8593; <IntlMessages id="release_to_refresh" /></h3>
                        }
                    >
                        {this.state.items.map((e, i) => (
                            <div className='place-item' key={`place-item-${i}`} onClick={() => window.location.href = '/place/detail/' + e.id}>
                                <div className='place-img' >
                                    <img src={e.main_image.S400 ? e.main_image.S400 : noImage800} alt="place-img" />
                                </div>
                                <div className='place-info'>
                                    <div className='place-info-header'>
                                        <p className='place-name color-black'>
                                            {getLocaleName(e, locale)}
                                        </p>
                                    </div>
                                    <div className='place-footer'>
                                        <div className='place-rate'>
                                            {[1, 2, 3, 4, 5].map((i) => {
                                                if (i <= e.rating) {
                                                    return <i className='icomoon-start-fill' key={`place-rate-${i}`}></i>
                                                } else {
                                                    return <i className='icomoon-start-empy' key={`place-rate-${i}`}></i>
                                                }
                                            })}
                                            <span>{e.rating.toFixed(1)}</span>
                                        </div>
                                        <div className='place-reviews'>
                                            {e.review_no} <IntlMessages id="reviews" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div >

                <Footer selectedIndex="1"></Footer>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(
    connect(mapStateToProps, mapActionsToProps)(Search)
);
