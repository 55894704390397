import { Map, GoogleApiWrapper, Polygon, InfoWindow, Marker } from 'google-maps-react';
import { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/common/Header';
import Loading from '../../components/common/Loading';
import { getZonesList } from '../../helper/FirestoreApi';
import { centerPoint, getGetParams, getLocaleDescription, getLocaleName, withNavigateHook } from '../../helper/Tools';
import ScrollContainer from 'react-indiana-drag-scroll'
import IntlMessages from '../../components/common/intlMessages';
import { googleKey } from '../../helper/defaultValues';

export class ProvinceZone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selctedZone: null,
            area: null,
            zones: [],
            readmore: false
        }
    }
    async componentDidMount() {
        let { area } = this.props;
        let { appid } = this.props.params;
        let selected = area.find((g) => g.id === appid);
        this.setState({
            area: selected,
        })
        if (selected) {
            let zones = await getZonesList(selected.value);
            zones.forEach((e, i) => {
                e.center = centerPoint(e.area)
            });
            this.setState({
                zones: zones,
            });
            var parts = getGetParams();
            if (parts.zone !== undefined) {
                let select = zones.find((e) => e.id === parts.zone);
                if (select) {
                    this.setState({
                        'selctedZone': select,
                    });
                }
            }
        }



    }

    render() {

        if (this.state.area === null) {
            return <Loading />
        }
        let { locale, google } = this.props;
        let lat, lng = 0;
        if (this.state.selctedZone === null) {
            lat = this.state.area.latitude;
            lng = this.state.area.longitude;
        } else {
            lat = this.state.selctedZone.center.latitude;
            lng = this.state.selctedZone.center.longitude;
        }
        return (
            <div className='default-content province-area-content'>
                <Header isBack={true} title={getLocaleName(this.state.area, locale)} />
                <ScrollContainer className="zone-header scroll-container">
                    <div className={`zone-header-item ${this.state.selctedZone === null ? 'active' : ''}`} onClick={() => this.setState({ selctedZone: null })}>
                        <div className='zone-header-all'>All</div>
                        <p><IntlMessages id="general.zone_all"></IntlMessages></p>
                    </div>
                    {this.state.zones.length > 0 &&
                        this.state.zones.map((zone, i) => {
                            return <div className={`zone-header-item ${this.state.selctedZone?.id === zone.id ? 'active' : ''}`} key={`zone-header-${i}`} onClick={() => this.setState({ selctedZone: zone })}>
                                <img src={zone.image_circle} alt="zone-img" />
                                <p>{getLocaleName(zone, locale)}</p>
                            </div>;
                        })
                    }
                </ScrollContainer>
                <Map google={google}
                    className="area-map"
                    initialCenter={{
                        lat: lat,
                        lng: lng
                    }}
                    clickableIcons={false}
                    zoom={12}>
                    {this.state.zones.length > 0 &&
                        this.state.zones.filter((e) => e.id !== this.state.selctedZone?.id).map((zone, i) => {
                            return <Polygon
                                paths={zone.area.map((e) => {
                                    return { lat: e.latitude, lng: e.longitude };
                                })}
                                key={`polygon-${i}`}
                                strokeColor={'#2196F3'}
                                strokeOpacity={0.8}
                                strokeWeight={2}
                                fillColor={'#2196F3'}
                                fillOpacity={0.35}
                                onClick={() => this.setState({
                                    selctedZone: zone
                                })}
                            />;
                        })
                    }
                    {this.state.selctedZone && <Polygon
                        paths={this.state.selctedZone.area.map((e) => {
                            return { lat: e.latitude, lng: e.longitude };
                        })}
                        strokeColor={"#FFC524"}
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor={"#FFC524"}
                        fillOpacity={0.35}
                        onClick={() => this.setState({
                            selctedZone: null
                        })}
                    />}
                    {this.state.zones.length > 0 &&
                        this.state.zones.filter((e) => e.id !== this.state.selctedZone?.id).map((zone, i) => {
                            return <Marker
                                label={{
                                    text: getLocaleName(zone, locale),
                                    className: 'marker-label'
                                }}
                                position={{ lat: zone.center.latitude, lng: zone.center.longitude }}
                                icon={{
                                    url: "/assets/img/custom-marker.png",
                                    labelOrigin: new google.maps.Point(105, 24)
                                }}
                                onClick={() => this.setState({
                                    selctedZone: zone
                                })}
                            />;
                        })
                    }

                    <InfoWindow
                        position={{ lat: lat, lng: lng }}
                        visible={this.state.selctedZone !== null}>
                        <div className='info-content'>
                            <div className='info-header'>
                                <div className='info-img'><img src={this.state.selctedZone?.image_square} alt="info-img" /></div>
                                <div className='info-detail'>
                                    <p className='zone-name'>{getLocaleName(this.state.selctedZone, locale)}</p>
                                    <p className={'zone-detail'}>{getLocaleDescription(this.state.selctedZone, locale)}</p>
                                    <div className='text-end'>
                                        <a href={'/zones/detail/' + this.state.selctedZone?.id} className='zone-detail-link'>
                                            {(() => {
                                                if (locale === 'en') {
                                                    return "Look Around";
                                                } else if (locale === 'th') {
                                                    return "ดูสถานที่รอบๆ";
                                                }
                                            })()}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    const { locale } = state.language;
    const { area } = state.area;
    return { locale, area };
};

const mapActionsToProps = {};

export default withNavigateHook(GoogleApiWrapper((props) => ({
    apiKey: googleKey,
    language: 'en',
}))(connect(mapStateToProps, mapActionsToProps)(ProvinceZone)));
