import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getLocaleName } from '../../../helper/Tools';
import { changeLanguage } from '../../../redux/lang/actions';
import IntlMessages from '../../../components/common/intlMessages';

const tabs = [
  {
    id: 1,
    name: 'general.menu-place',
    icon: 'icomoon-favourite',
    tab: 'place',
  },
  {
    id: 2,
    name: 'general.menu-ticket',
    icon: 'icomoon-ticket',
    tab: 'ticket',
  },
  {
    id: 3,
    name: 'general.menu-transit',
    icon: 'icomoon-route',
    tab: 'transit',
  },
  {
    id: 4,
    name: 'general.menu-shopping',
    icon: 'icomoon-shopping',
    tab: 'shopping',
  },
  {
    id: 5,
    name: 'general.menu-3d',
    icon: 'icomoon-meta-3d',
    tab: '3d',
  },
];

class HomeHeader extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.isSticky);
    return () => {
      window.removeEventListener('scroll', this.isSticky);
    };
  }

  /* Method that will fix header after a specific scrollable */
  isSticky(e) {
    const header = document.querySelector('.home-header');
    const scrollTop = window.scrollY;
    if (scrollTop >= 320) {
      header.classList.add('level4');
    } else if (scrollTop >= 200) {
      header.classList.remove('level4');
      header.classList.add('level3');
    } else if (scrollTop >= 150) {
      header.classList.remove('level3');
      header.classList.remove('level4');
      header.classList.add('level2');
    } else if (scrollTop >= 80) {
      header.classList.remove('level2');
      header.classList.remove('level3');
      header.classList.remove('level4');
      header.classList.add('level1');
    } else {
      header.classList.remove('level1');
      header.classList.remove('level2');
      header.classList.remove('level3');
      header.classList.remove('level4');
    }
    header.style.height = Math.max(480 - scrollTop, 150) + "px";

  }
  render() {
    const {
      areas,
      selectedArea,
      locale,
      setHomeState,
      selectedTab,
      setSelectTabState,
    } = this.props;

    return (
      <header className="home-header" style={{ 'height': '480px' }}>
        <div className="content-box">
          <h1 className="header-title">treasure</h1>
          <div className="header-tabs">
            {tabs.map((e, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div
                className={
                  'header-tab-item ' + (e.id === selectedTab ? 'active' : '')
                }
                key={`home-place-key-${index}`}
                onClick={() => {
                  setSelectTabState(e.id);
                }}
              >
                <i className={e.icon}></i>
                <p className="tab-item-title">
                  <IntlMessages id={e.name} />
                </p>
              </div>
            ))}
          </div>
          {(() => {
            switch (selectedTab) {
              case 1:
                return (
                  <div className={'ballon ballon-top tab-' + selectedTab}>
                    <div className="tab-content">
                      <div className="top-content-header">
                        <div className="top-header-title">
                          <IntlMessages id="general.place-select-region" />
                        </div>
                        <div className="top-header-selector">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="header-selector"
                              id="dropdown-basic"
                            >
                              {selectedArea === null ? (
                                <IntlMessages id="general.area-thailand" />
                              ) : (
                                getLocaleName(selectedArea, locale)
                              )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  setHomeState(null);
                                }}
                              >
                                {' '}
                                <IntlMessages id="general.area-thailand" />
                              </Dropdown.Item>
                              {areas.map((e, i) => (
                                <Dropdown.Item
                                  key={'header-area-' + i}
                                  href="#"
                                  onClick={() => {
                                    setHomeState(e);
                                  }}
                                >
                                  {getLocaleName(e, locale)}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="header-search-bar">
                        <div className="search-bar-icon">
                          <i className="icomoon-search-icon"></i>
                        </div>
                        <div className="search-bar-text">
                          <IntlMessages id="general.header-search-places" />
                        </div>
                      </div>
                      <div className="header-btn-actions">
                        <a
                          className="header-btn-search place-btn"
                          href="/place/search"
                        >
                          <i className="icomoon-search-icon"></i>
                          <IntlMessages id="general.header-search-btn" />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              case 2:
                return (
                  <div className={'ballon ballon-top tab-' + selectedTab}>
                    <div className="tab-content">
                      <div className="top-content-header">
                        <div className="top-header-title">
                          <IntlMessages id="general.place-select-region" />
                        </div>
                        <div className="top-header-selector">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="header-selector"
                              id="dropdown-basic"
                            >
                              {selectedArea === null ? (
                                <IntlMessages id="general.area-thailand" />
                              ) : (
                                getLocaleName(selectedArea, locale)
                              )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  setHomeState(null);
                                }}
                              >
                                {' '}
                                <IntlMessages id="general.area-thailand" />
                              </Dropdown.Item>
                              {areas.map((e, i) => (
                                <Dropdown.Item
                                  key={'header-area-' + i}
                                  href="#E"
                                  onClick={() => {
                                    setHomeState(e);
                                  }}
                                >
                                  {getLocaleName(e, locale)}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="header-search-bar">
                        <div className="search-bar-icon">
                          <i className="icomoon-search-icon"></i>
                        </div>
                        <div className="search-bar-text">
                          <IntlMessages id="general.header-search-activities" />
                        </div>
                      </div>
                      <div className="header-btn-actions">
                        <a
                          className="header-btn-search activity-btn"
                          href="/comingSoon"
                        >
                          <i className="icomoon-search-icon"></i>
                          <IntlMessages id="general.header-search-btn" />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              case 3:
                return (
                  <div className={'ballon ballon-top tab-' + selectedTab}>
                    <div className="tab-content">
                      <div className="top-content-header">
                        <div className="top-header-title">
                          <IntlMessages id="general.header-transit-from" /> :
                        </div>
                      </div>
                      <div className="top-content-header">
                        <div className="top-header-title">
                          <IntlMessages id="general.header-transit-to" /> :
                        </div>
                        <div className="search-bar-icon">
                          <i className="icomoon-location-icon"></i>
                        </div>
                      </div>
                      <div className="header-btn-actions">
                        <a
                          className="header-btn-search transit-btn"
                          href="/comingSoon"
                        >
                          <i className="icomoon-search-icon"></i>
                          <IntlMessages id="general.header-search-btn" />
                        </a>
                      </div>
                    </div>
                  </div>
                );

              case 4:
                return (
                  <div className={'ballon ballon-top tab-' + selectedTab}>
                    <div className="tab-content">
                      <div className="tab-content">
                        <div className="top-content-header">
                          <div className="top-header-title">
                            <IntlMessages id="general.header-shopping-looking-for" />{' '}
                            :
                          </div>
                        </div>
                        <div className="header-search-bar">
                          <div className="search-bar-icon">
                            <i className="icomoon-search-icon"></i>
                          </div>
                          <div className="search-bar-text">
                            <IntlMessages id="general.header-search-shopping" />
                          </div>
                        </div>
                        <div className="header-btn-actions">
                          <a
                            className="header-btn-search shopping-btn"
                            href="/comingSoon"
                          >
                            <i className="icomoon-search-icon"></i>
                            <IntlMessages id="general.header-search-btn" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              case 5:
                return (
                  <div className="tab-content vr-content">
                    <div className="vr-content-title">
                      <IntlMessages id="general.header-vr-title" />
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })()}
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.language;
  return { locale };
};

const mapDispatchToProps = (dispatch) => ({
  changLang: (val) => {
    dispatch(changeLanguage(val));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);
