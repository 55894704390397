export const localeOptions = [
  { id: 'en', name: 'English' },
  { id: 'th', name: 'Thai' },
  { id: 'ko', name: 'Korean' },
];
export const defaultCurrency = 'thb';

export const firebaseConfig = {
  apiKey: 'AIzaSyBw0JXhlmWBNm9WIUEnpzAu0EP2ZbOfmmo',
  authDomain: 'treasure-trip-4aa5a.firebaseapp.com',
  projectId: 'treasure-trip-4aa5a',
  storageBucket: 'treasure-trip-4aa5a.appspot.com',
  messagingSenderId: '321559303120',
  appId: '1:321559303120:web:7ef85384b85998d75e9fd5',
  measurementId: 'G-NFYB13T2J5',
};

export const currentUser = null;

export const interTransit = [
  {
    id: '',
    name: 'home.transport_demestic_flight',
    image: '/assets/icons/trasnport-flight.png',
    link: '/transit/airplane',
  },
  {
    id: '',
    name: 'home.transport_trains',
    image: '/assets/icons/trasnport-trains.png',
    link: '/transit/method?type=train',
  },
  {
    id: '',
    name: 'home.transport_express_bus',
    image: '/assets/icons/trasnport-express-bus.png',
    link: '/transit/method?type=bus',
  },
  {
    id: '',
    name: 'home.transport_mini_van',
    image: '/assets/icons/trasnport-mini-van.png',
    link: '/transit/method?type=van',
  },
];
export const intraTransit = [
  {
    id: 'bts',
    name: 'home.transport_mrt_bts',
    image: '/assets/icons/trasnport-MRT-BTS.png',
    link: '/transit/airplane',
  },
  {
    id: 'songthaew',
    name: 'home.transport_songthaew',
    image: '/assets/icons/trasnport-songthaew.png',
    link: '/transit/airplane',
  },
  {
    id: 'bus',
    name: 'home.transport_bus',
    image: '/assets/icons/trasnport-bus.png',
    link: '/transit/airplane',
  },
  {
    id: 'ferry',
    name: 'home.transport_boat_ferry',
    image: '/assets/icons/trasnport-boat-ferry.png',
    link: '/transit/method?type=ferry',
  },
];

export const noImage800 =
  "https://firebasestorage.googleapis.com/v0/b/bangkok-metro-trip.appspot.com/o/assets%2Ficons%2Fno-image-800.jpg?alt=media&token=6dc02bce-0afe-404e-a02d-7153401e39c7";
export const noImage1200 =
  "https://firebasestorage.googleapis.com/v0/b/bangkok-metro-trip.appspot.com/o/assets%2Ficons%2Fno-image-1200.jpg?alt=media&token=6dc02bce-0afe-404e-a02d-7153401e39c7";

export const noImage80 =
  "https://firebasestorage.googleapis.com/v0/b/bangkok-metro-trip.appspot.com/o/assets%2Ficons%2Fno-image-80.jpg?alt=media&token=952938b9-be86-43ed-a962-fa7ebfe70120";
export const noImage400 =
  "https://firebasestorage.googleapis.com/v0/b/bangkok-metro-trip.appspot.com/o/assets%2Ficons%2Fno-image-400.jpg?alt=media&token=113ce2fe-87e9-4f54-b15a-b617f6740621";
export const noImage180 =
  "https://firebasestorage.googleapis.com/v0/b/bangkok-metro-trip.appspot.com/o/assets%2Ficons%2Fno-image-180.jpg?alt=media&token=f6e8b740-1abf-4a2f-b643-a5feb9933eac";
export const stopDefault =
  "https://firebasestorage.googleapis.com/v0/b/treasure-trip-4aa5a.appspot.com/o/bus-stop.png?alt=media&token=da858e77-a11c-487a-8583-e41fd943b1f3";


export const googleKey = "AIzaSyAX1ihNIWMxOCfmqX8wXkDl_jGhkNyMKK8";
// export const googleKey = "AIzaSyBw0JXhlmWBNm9WIUEnpzAu0EP2ZbOfmmo";

export const lineColorList = [
  "#5928E0",
  "#A822DC",
  "#EB5085",
  "#f44236",
  "#009788",
  "#3f51b5",
  "#607d8b",
  "#795547",
  "#9e9e9e",
  "#000000",
]