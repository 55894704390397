import { localeOptions } from './defaultValues';

export const defaultLocale = 'th';

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('locale') &&
        localeOptions.filter((x) => x.id === localStorage.getItem('locale'))
          .length > 0
        ? localStorage.getItem('locale')
        : defaultLocale;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getlocale -> error', error);
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('locale', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getAreaList = () => {
  let result = [];
  try {
    result = localStorage.getItem('area')
      ? JSON.parse(localStorage.getItem('area'))
      : [];
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getlocale -> error', error);
  }
  return result;
};
export const setAreaList = (area) => {
  try {
    localStorage.setItem('area', JSON.stringify(area));
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};
export const getAreaUpdated = () => {
  let result = null;
  try {
    result = localStorage.getItem('updated')
      ? localStorage.getItem('updated')
      : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getlocale -> error', error);
  }
  return result;
};
export const setAreaUpdated = (area) => {
  try {
    let yourDate = new Date();
    localStorage.setItem('updated', yourDate.toISOString().split('T')[0]);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getRedirectDate = () => {
  let result = null;
  try {
    result = localStorage.getItem('redirect')
      ? localStorage.getItem('redirect')
      : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getlocale -> error', error);
  }
  return result;
};
export const setRedirectDate = () => {
  try {
    let yourDate = new Date();
    localStorage.setItem('redirect', yourDate.toISOString().split('T')[0]);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};
