import React from 'react';
import { connect } from 'react-redux';
import { getGetParams, withNavigateHook } from '../../helper/Tools';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getBlogViewsList } from '../../helper/FirestoreApi';
import IntlMessages from '../../components/common/intlMessages';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { noImage180, noImage800 } from '../../helper/defaultValues';


class BlogList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            tag: null,
            isEnd: false,
            isLoad: false,
            zone: 'sandbox'
        };
    }
    async componentDidMount() {

        var parts = getGetParams();
        if (parts.zone !== undefined) {
            this.setState({
                'zone': parts.zone,
            });
            this.refresh(parts.zone)

        } else {
            this.refresh('sandbox')
        }
    }
    fetchData(zone) {
        let { locale } = this.props;

        if (this.state.isLoad) {
            return;
        }
        this.setState({
            isLoad: true
        })
        var last = this.state.items.slice(-1)[0]

        getBlogViewsList(zone, locale, last).then((value) => {
            this.setState({
                isLoad: false,
                isEnd: value.length < 12,
                items: this.state.items.concat(value)
            });
        });
    }
    refresh(zone) {
        let { locale } = this.props;

        if (this.state.isLoad) {
            return;
        }
        this.setState({
            isLoad: true
        })
        getBlogViewsList(zone, locale, null).then((value) => {
            this.setState({
                isLoad: false,
                isEnd: value.length < 12,
                items: value
            });
        });
    }


    render() {
        return (
            <div className="default-content">
                <Header isBack={true} title={<IntlMessages id="recommend_reading" />} />
                <div className='content-box '>
                    <InfiniteScroll
                        className='recommend-blogs-list'
                        dataLength={this.state.items.length} //This is important field to render the next data
                        next={() => this.fetchData(this.state.zone)}
                        hasMore={!this.state.isEnd}
                        loader={<div className='text-center'><div className='spinner'><div></div><div></div><div></div></div></div>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b> <IntlMessages id="list_see_all" /></b>
                            </p>
                        }
                        // below props only if you need pull down functionality
                        refreshFunction={() => this.refresh(this.state.zone)}
                        pullDownToRefresh
                        pullDownToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>
                                &#8595; <IntlMessages id="pull_down_refresh" />
                            </h3>
                        }
                        releaseToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>&#8593; <IntlMessages id="release_to_refresh" /></h3>
                        }
                    >
                        {this.state.items.map((e, i) => (
                            <div className='blog-item' key={`blog-item-${i}`} >
                                <div className='blog-img' >
                                    <b className='blog-marker'>{i + 1}</b>
                                    <img src={e.cover !== '' ? e.cover : noImage800} alt="blog-img" onClick={
                                        () => {
                                            window.location.href = '/blog/detail/' + e.id;
                                        }
                                    } />
                                    {i < 2 && <p className='place-name'><i className='icomoon-nearby'></i> {e.place.name}</p>}
                                </div>
                                <div className='blog-detail'>
                                    <p className='blog-title' onClick={
                                        () => {
                                            window.location.href = '/blog/detail/' + e.id;
                                        }
                                    }>{e.title}</p>
                                    {i > 1 && <p className='blog-place'><i className='icomoon-nearby'></i> {e.place.name}</p>}
                                    <div className='blog-author'>
                                        <div className='author-icon'>
                                            <img src={e.user?.photo !== '' ? e.user?.photo : noImage180} alt="author-img" />
                                        </div>
                                        <div className='author-info'>
                                            <p className='author-by'><IntlMessages id="reviewed_by" /></p>
                                            <p className='author-name'>{e.user.name}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div >
                <Footer selectedIndex="1"></Footer>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    const { locale } = state.language;
    return { locale };
};

const mapActionsToProps = {};

export default withNavigateHook(
    connect(mapStateToProps, mapActionsToProps)(BlogList)
);
